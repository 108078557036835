let data95;
if (typeof window === "undefined") {
  data95 = require("../static-s95/Data.json");
} else {
  // data95 = require("../static-s95/Data.json");
  // window.data95 = data95;
  data95 = window.data95;
}

export { data95 };
