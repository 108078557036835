let data11;
if (typeof window === "undefined") {
  data11 = require("../static-s11/Data.json");
} else {
  // data11 = require("../static-s11/Data.json");
  // window.data11 = data11;
  data11 = window.data11;
}

export { data11 };
