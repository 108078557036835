// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import Snackbar from "@mui/material/Snackbar";

var sub = {
  contents: undefined
};

function sendMsg(msg) {
  var cb = sub.contents;
  if (cb !== undefined) {
    return Curry._1(cb, msg);
  }
  
}

function SnackbarService(Props) {
  var match = React.useState(function () {
        
      });
  var setMessage = match[1];
  var message = match[0];
  sub.contents = (function (msg) {
      return Curry._1(setMessage, (function (param) {
                    return msg;
                  }));
    });
  return React.createElement(Snackbar, {
              open: Belt_Option.isSome(message),
              onClose: (function (param) {
                  return Curry._1(setMessage, (function (param) {
                                
                              }));
                }),
              message: Belt_Option.getWithDefault(message, ""),
              autoHideDuration: 6000
            });
}

var make = React.memo(SnackbarService);

export {
  sub ,
  sendMsg ,
  make ,
  
}
/* make Not a pure module */
