import Settings from "./Settings";
import { Modal } from "@mantine/core"

type Props = {
  opened: boolean;
  onClose: () => void;
}

export const SettingsModal = ({ opened, onClose }: Props) => {
  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false}>
      <Settings />
    </Modal>
  );
};

export default SettingsModal;
