// Generated by ReScript, PLEASE EDIT WITH CARE

import AppHeaderTsx from "./AppHeader.tsx";

var make = AppHeaderTsx;

export {
  make ,
  
}
/* make Not a pure module */
