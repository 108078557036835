// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fnv1aBindJs from "./Fnv1a.bind.js";

function hash(prim) {
  return Fnv1aBindJs.hash(prim);
}

function ritoHash(input) {
  return Fnv1aBindJs.hash(input.toLowerCase()).toString(16).padStart(8, "0");
}

export {
  hash ,
  ritoHash ,
  
}
/* ./Fnv1a.bind.js Not a pure module */
