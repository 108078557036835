// Generated by ReScript, PLEASE EDIT WITH CARE

import CmdPaletteTsx from "./CmdPalette.tsx";

var make = CmdPaletteTsx;

export {
  make ,
  
}
/* make Not a pure module */
