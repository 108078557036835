// Generated by ReScript, PLEASE EDIT WITH CARE

import AppBoundaryBindJs from "./AppBoundary.bind.js";

var make = AppBoundaryBindJs;

export {
  make ,
  
}
/* make Not a pure module */
