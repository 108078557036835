// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function map(p, fn) {
  return p.then(function (v) {
              return Promise.resolve(Curry._1(fn, v));
            });
}

function map2(p, fn, fn2) {
  var __x = p.then(function (v) {
        return Promise.resolve(Curry._1(fn, v));
      });
  return __x.catch(function (e) {
              return Promise.resolve(Curry._1(fn2, e));
            });
}

function flatMap(p, fn) {
  return p.then(Curry.__1(fn));
}

function consume(p, fn) {
  p.then(function (v) {
        Curry._1(fn, v);
        return Promise.resolve(undefined);
      });
  
}

function consume2(p, fn, errFn) {
  var __x = p.then(function (v) {
        Curry._1(fn, v);
        return Promise.resolve(undefined);
      });
  __x.catch(function (err) {
        Curry._1(errFn, err);
        return Promise.resolve(undefined);
      });
  
}

function ignore(prim) {
  
}

export {
  map ,
  map2 ,
  flatMap ,
  consume ,
  consume2 ,
  ignore ,
  
}
/* No side effect */
