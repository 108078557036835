// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CdnAtom from "common/src/atoms/CdnAtom.bs.js";
import * as ImgCore from "common/src/tft-static/ImgCore.bs.js";

function LlIcon(Props) {
  var companionId = Props.companionId;
  var size = Props.size;
  var className = Props.className;
  var basePath3 = CdnAtom.usePath4(undefined);
  var sizeKey = String(size);
  var companionSrc = basePath3 + "/ll-icons/" + companionId + ".png?w=" + sizeKey;
  var match = ImgCore.getSrcSet2(basePath3 + "/ll-icons/" + companionId + ".png", size, size, size, size);
  var sizeClass = match[2];
  return React.createElement("img", {
              loading: "lazy",
              decoding: "async",
              alt: "Tactician",
              className: className !== undefined ? className + " rounded-full " + sizeClass : "rounded-full " + sizeClass,
              src: companionSrc,
              srcSet: match[1],
              sizes: match[0],
              width: sizeKey,
              height: sizeKey
            });
}

var make = React.memo(LlIcon);

export {
  make ,
  
}
/* make Not a pure module */
