import { getAbKey } from "./Ab.bs.js";

// export const getInstance = () => amplitude.getInstance();
// return import("amplitude-js").then((amp) => amp.default.getInstance());
export const log = (e, params) => {
  const abKey = getAbKey();
  if (abKey < 50) {
    window.ampLog(e, params);
  }
};
export const setUserId = (e, params) => {
  const abKey = getAbKey();
  if (abKey < 50) {
    window.ampSetUserId(e, params);
  }
};
