// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lzy from "../util/Lzy.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Data10BindJs from "./Data10.bind.js";

var data = Data10BindJs.data10;

var units = data.units;

var traits = data.traits;

var items = data.items;

var augments = data.augments;

var itemsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(items), (function (param) {
              var v = param[1];
              arr[v.id] = [
                param[0],
                v
              ];
              
            }));
      return arr;
    });

function itemsArr() {
  return Lzy.get(itemsArr_);
}

var augmentsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(augments), (function (param) {
              var v = param[1];
              if (Belt_Option.isNone(v.typ)) {
                arr[v.id] = [
                  param[0],
                  v
                ];
                return ;
              }
              
            }));
      return arr;
    });

function augmentsArr() {
  return Lzy.get(augmentsArr_);
}

var hrAugmentsArr = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(augments), (function (param) {
              var v = param[1];
              if (Caml_obj.caml_equal(v.typ, "hr")) {
                arr[v.id] = [
                  param[0],
                  v
                ];
                return ;
              }
              
            }));
      return arr;
    });

function gridItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var y$1 = match[1];
  var x$1 = match[0];
  if (y$1 === 9) {
    if (x$1 === 9) {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45);
    } else if (x$1 === 6) {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 127);
    } else {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45 + x$1 | 0);
    }
  } else {
    return Belt_Array.getExn(Lzy.get(itemsArr_), (((1 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + y$1 | 0);
  }
}

function gridRadiantItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var x$1 = match[0];
  return Belt_Array.getExn(Lzy.get(itemsArr_), (((46 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + match[1] | 0);
}

function getItemTyp(id) {
  if (id < 45) {
    return "Craftable";
  } else if (id <= 53) {
    return "CraftableEmblem";
  } else if (id < 90) {
    return "Radiant";
  } else if (id < 104 || id === 132 || id === 133) {
    return "Ornn";
  } else if (id < 117) {
    return "Support";
  } else if (id < 132) {
    return "ElusiveEmblem";
  } else {
    return "Other";
  }
}

var craftableSpats = [
  "10TrueDamageEmblem",
  "108bitEmblem",
  "10KDAEmblem",
  "10EmoEmblem",
  "10PentakillEmblem",
  "10CountryEmblem",
  "10PBJEmblem",
  "10PunkEmblem"
];

var elusiveSpats = [
  "10WardenEmblem",
  "10SuperfanEmblem",
  "10SpellweaverEmblem",
  "10RapidfireEmblem",
  "10FighterEmblem",
  "10GuardianEmblem",
  "10HyperPopEmblem",
  "10ExecutionerEmblem",
  "10EdgelordEmblem",
  "10DiscoEmblem",
  "10JazzEmblem",
  "10DazzlerEmblem",
  "10CrowdDiverEmblem",
  "10BrawlerEmblem",
  "10BigShotEmblem"
];

export {
  data ,
  units ,
  traits ,
  items ,
  augments ,
  itemsArr_ ,
  itemsArr ,
  augmentsArr_ ,
  augmentsArr ,
  hrAugmentsArr ,
  gridItem ,
  gridRadiantItem ,
  getItemTyp ,
  craftableSpats ,
  elusiveSpats ,
  
}
/* data Not a pure module */
