// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Jotai from "jotai";
import * as React from "react";
import * as $$Promise from "../Promise.bs.js";
import * as CdnAtomBindJs from "./CdnAtom.bind.js";

function checkCdn(prim) {
  return CdnAtomBindJs.checkCdn();
}

function checkCdn3(prim) {
  return CdnAtomBindJs.checkCdn3();
}

var avifSupport = CdnAtomBindJs.avifSupport;

var useAvifSupportSetter = CdnAtomBindJs.useAvifSupportSetter;

var fallbackCdn = Jotai.atom(false);

function useSetter(param) {
  var match = Jotai.useAtom(fallbackCdn);
  var setFallback = match[1];
  React.useEffect((function () {
          var fetched = {
            contents: false
          };
          $$Promise.consume2(CdnAtomBindJs.checkCdn(), (function (param) {
                  fetched.contents = true;
                  
                }), (function (param) {
                  return Curry._1(setFallback, true);
                }));
          setTimeout((function (param) {
                  if (!fetched.contents) {
                    return Curry._1(setFallback, true);
                  }
                  
                }), 1500);
          
        }), []);
  
}

function usePath(param) {
  return "https://cdn.tft.tools";
}

var fallbackCdn3 = Jotai.atom(false);

function useSetter3(param) {
  var match = Jotai.useAtom(fallbackCdn3);
  var setFallback = match[1];
  React.useEffect((function () {
          var fetched = {
            contents: false
          };
          $$Promise.consume2(CdnAtomBindJs.checkCdn3(), (function (param) {
                  fetched.contents = true;
                  return Curry._1(setFallback, false);
                }), (function (param) {
                  return Curry._1(setFallback, true);
                }));
          setTimeout((function (param) {
                  if (!fetched.contents) {
                    return Curry._1(setFallback, true);
                  }
                  
                }), 2000);
          
        }), []);
  
}

function usePath4(param) {
  var match = Jotai.useAtom(fallbackCdn3);
  var match$1 = Jotai.useAtom(avifSupport);
  if (match[0]) {
    return "https://cdn.tft.tools";
  } else {
    return "https://ap.tft.tools/img" + (
            match$1[0] ? "" : "2"
          );
  }
}

function usePath5(param) {
  Jotai.useAtom(fallbackCdn3);
  Jotai.useAtom(avifSupport);
  return "https://ap.tft.tools";
}

export {
  checkCdn ,
  checkCdn3 ,
  avifSupport ,
  useAvifSupportSetter ,
  fallbackCdn ,
  useSetter ,
  usePath ,
  fallbackCdn3 ,
  useSetter3 ,
  usePath4 ,
  usePath5 ,
  
}
/* avifSupport Not a pure module */
