Array.prototype.filterMap = function (filter) {
  const r = [];
  for (let i = 0; i < this.length; i++) {
    const item = filter(this[i], i);
    if (item !== undefined) {
      r.push(item);
    }
  }
  return r;
};

export default null;
