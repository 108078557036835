// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Fnv1a from "../bindings/Fnv1a.bs.js";
import * as React from "react";
import * as Colors from "../Colors.bs.js";
import * as Global from "../Global.bs.js";
import * as CdnAtom from "../atoms/CdnAtom.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getVariableHashKey(variableName) {
  return "{" + Fnv1a.ritoHash(variableName) + "}";
}

function replaceItemVariables(desc, item) {
  var parts = Belt_Array.map(desc.split(new RegExp("(@.+?@)", "gm")), Belt_Option.getExn);
  return Belt_Array.map(parts, (function (part) {
                  if (!part.startsWith("@")) {
                    return part;
                  }
                  var n = part.slice(1, part.length - 1 | 0);
                  var variableBaseName = n === "Lifesteal" ? "LifeSteal" : n;
                  var variableName = Belt_Array.getExn(variableBaseName.split("*"), 0);
                  var match = Js_dict.get(item.effects, variableName);
                  var match$1 = Js_dict.get(item.effects, getVariableHashKey(variableName));
                  var v1 = match !== undefined ? match : (
                      match$1 !== undefined ? match$1 : undefined
                    );
                  var value = v1 === "null" ? 0 : Belt_Option.getWithDefault(v1, 0);
                  return Global.toFixedSmart(variableBaseName.includes("*100") ? value * 100 : value);
                })).join("");
}

function ignoreKeywords(desc) {
  return desc.replace(new RegExp("(<TFTKeyword>|</TFTKeyword>|<tftbold>|</tftbold>|<scaleAP>|</scaleAP>|<scaleArmor>|</scaleArmor>|<scaleMR>|</scaleMR>|<scaleAS>|</scaleAS>)", "gm"), "");
}

function parse(desc) {
  var desc$1 = ignoreKeywords(desc);
  return Belt_Array.map(Belt_Array.map(desc$1.split(new RegExp("(<b>.+?</b>|<tftstrong>.+?</tftstrong>|<keywordMajor>.+?</keywordMajor>|<scaleAD>|%i:scaleAD%</scaleAD>|</scaleAD>|<trueDamage>|</trueDamage>|<TFTDebonairVIP>.+?</TFTDebonairVIP>|<tftitemrules>.+?</tftitemrules>|%i:goldCoins%|%i:scaleRange%|%i:scaleCrit%|%i:scaleCritMult%|%i:scaleAS%|%i:scaleAP%|%i:scaleHealth%|%i:scaleMana%|%i:scaleAD%|%i:scaleArmor%|%i:scaleMR%|<br><br>|<br>|%i:star%|<TFTShadowItemBonus>.+?</TFTShadowItemBonus>|<TFTRadiantItemBonus>.+?</TFTRadiantItemBonus>|<TFTShadowItemPenalty>.+?</TFTShadowItemPenalty>|<li>.+?</li>|<i>.+?</i>|<scaleAP>.+?</scaleAP>)", "gm")), Belt_Option.getExn), (function (part) {
                switch (part) {
                  case "%i:goldCoins%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* Gold */10
                            };
                  case "%i:scaleAD%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* AD */0
                            };
                  case "%i:scaleAP%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* AP */1
                            };
                  case "%i:scaleAS%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* AS */6
                            };
                  case "%i:scaleArmor%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* Armor */4
                            };
                  case "%i:scaleCrit%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* CritChance */9
                            };
                  case "%i:scaleCritMult%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* CritDmg */8
                            };
                  case "%i:scaleHealth%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* HP */2
                            };
                  case "%i:scaleMR%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* MR */5
                            };
                  case "%i:scaleMana%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* Mana */3
                            };
                  case "%i:scaleRange%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* Range */11
                            };
                  case "%i:star%" :
                      return {
                              TAG: /* Icon */0,
                              _0: /* Star */7
                            };
                  case "<br>" :
                      return /* LineBreak */0;
                  case "<br><br>" :
                      return /* DoubleLineBreak */1;
                  case "%i:scaleAD%</scaleAD>" :
                  case "</scaleAD>" :
                  case "</trueDamage>" :
                  case "<scaleAD>" :
                  case "<trueDamage>" :
                      return {
                              TAG: /* Text */3,
                              _0: ""
                            };
                  default:
                    if (part.startsWith("<b>")) {
                      return {
                              TAG: /* Bold */12,
                              _0: part.slice(3, part.length - 4 | 0)
                            };
                    }
                    if (part.startsWith("<tftstrong>")) {
                      return {
                              TAG: /* Bold */12,
                              _0: part.slice(11, part.length - 12 | 0)
                            };
                    }
                    if (part.startsWith("<scaleAP>")) {
                      return {
                              TAG: /* ApScaling */7,
                              _0: part.slice(9, part.length - 10 | 0)
                            };
                    }
                    if (part.startsWith("<keywordMajor>")) {
                      return {
                              TAG: /* Bold */12,
                              _0: part.slice(14, part.length - 15 | 0)
                            };
                    }
                    if (!part.startsWith("<tftitemrules>[")) {
                      if (part.startsWith("<tftitemrules>")) {
                        return {
                                TAG: /* TftItemRules */1,
                                _0: parse(part.slice(14, part.length - 15 | 0))
                              };
                      } else if (part.startsWith("<TFTDebonairVIP>")) {
                        return {
                                TAG: /* DebonairVIP */14,
                                _0: part.slice(16, part.length - 17 | 0)
                              };
                      } else if (part.startsWith("<TFTShadowItemBonus>")) {
                        return {
                                TAG: /* ShadowItemBonus */5,
                                _0: parse(part.slice(20, part.length - 21 | 0))
                              };
                      } else if (part.startsWith("<TFTRadiantItemBonus>")) {
                        return {
                                TAG: /* RadiantItemBonus */4,
                                _0: parse(part.slice(21, part.length - 22 | 0))
                              };
                      } else if (part.startsWith("<TFTShadowItemPenalty>")) {
                        return {
                                TAG: /* ShadowItemPenalty */6,
                                _0: parse(part.slice(22, part.length - 23 | 0))
                              };
                      } else if (part.startsWith("<li>")) {
                        return {
                                TAG: /* Row */11,
                                _0: parse(part.slice(4, part.length - 5 | 0))
                              };
                      } else if (part.startsWith("<i>")) {
                        return {
                                TAG: /* Italic */2,
                                _0: part.slice(3, part.length - 4 | 0)
                              };
                      } else {
                        return {
                                TAG: /* Text */3,
                                _0: part
                              };
                      }
                    }
                    var endCut = part.endsWith("]</tftitemrules>") ? 16 : 15;
                    return {
                            TAG: /* TftItemRules */1,
                            _0: parse(part.slice(15, part.length - endCut | 0))
                          };
                }
              }));
}

function simplifyParts(parts) {
  if (parts.length === 0) {
    return [];
  }
  var $$final = [];
  var curr = {
    contents: Belt_Array.getExn(parts, 0)
  };
  Belt_Array.forEach(Belt_Array.sliceToEnd(parts, 1), (function (p) {
          var match = curr.contents;
          if (typeof match !== "number" && match.TAG === /* Text */3 && typeof p !== "number" && p.TAG === /* Text */3) {
            curr.contents = {
              TAG: /* Text */3,
              _0: match._0 + p._0
            };
            return ;
          }
          $$final.push(curr.contents);
          curr.contents = p;
          
        }));
  $$final.push(curr.contents);
  return $$final;
}

function parseParts(parts) {
  return Belt_Array.concatMany(Belt_Array.map(simplifyParts(parts), (function (p) {
                    if (typeof p === "number") {
                      return [p];
                    } else if (p.TAG === /* Text */3) {
                      return parse(p._0);
                    } else {
                      return [p];
                    }
                  })));
}

var shadowItemBonus = CssJs.style([CssJs.color(CssJs.hex("66ccb8"))]);

var radiantItemBonus = CssJs.style([CssJs.color(CssJs.hex(Colors.radiant))]);

var shadowItemPenalty = CssJs.style([CssJs.color(CssJs.hex("cc4a33"))]);

var lineBreak = CssJs.style([CssJs.height(CssJs.px(6))]);

var doubleLineBreak = CssJs.style([CssJs.height(CssJs.px(10))]);

var Styles = {
  shadowItemBonus: shadowItemBonus,
  radiantItemBonus: radiantItemBonus,
  shadowItemPenalty: shadowItemPenalty,
  lineBreak: lineBreak,
  doubleLineBreak: doubleLineBreak
};

function renderToString(parts) {
  return Belt_Array.map(parts, (function (p) {
                  if (typeof p === "number") {
                    return "\n";
                  }
                  switch (p.TAG | 0) {
                    case /* Icon */0 :
                        switch (p._0) {
                          case /* Star */7 :
                              return "(based on star level)";
                          case /* AD */0 :
                          case /* AP */1 :
                          case /* HP */2 :
                          case /* Mana */3 :
                          case /* Armor */4 :
                          case /* MR */5 :
                          case /* AS */6 :
                          case /* CritDmg */8 :
                          case /* CritChance */9 :
                          case /* Gold */10 :
                          case /* Range */11 :
                              return "";
                          
                        }
                    case /* TftItemRules */1 :
                        return Belt_Array.map(p._0, (function (v) {
                                        return renderToString([v]);
                                      })).join("");
                    case /* RadiantItemBonus */4 :
                    case /* ShadowItemBonus */5 :
                    case /* ShadowItemPenalty */6 :
                        return p._0.join("");
                    case /* Row */11 :
                        return "\n" + p._0.join("");
                    default:
                      return p._0;
                  }
                })).join("");
}

function make(Props) {
  var highlightPartsOpt = Props.highlightParts;
  var sizeOpt = Props.size;
  var parts = Props.parts;
  var highlightParts = highlightPartsOpt !== undefined ? highlightPartsOpt : [];
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  var basePath = CdnAtom.usePath(undefined);
  var iconSizeStr = String(size);
  return Belt_Array.mapWithIndex(parts, (function (i, part) {
                var tmp;
                if (typeof part === "number") {
                  tmp = part === /* LineBreak */0 ? React.createElement("div", {
                          className: lineBreak
                        }) : React.createElement("div", {
                          className: doubleLineBreak
                        });
                } else {
                  switch (part.TAG | 0) {
                    case /* Icon */0 :
                        switch (part._0) {
                          case /* AD */0 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Attack damage",
                                    height: iconSizeStr,
                                    src: basePath + "/general/ad.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* AP */1 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Ability power",
                                    height: iconSizeStr,
                                    src: basePath + "/general/ap.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* HP */2 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Health",
                                    height: iconSizeStr,
                                    src: basePath + "/general/hp.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* Mana */3 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Mana",
                                    height: iconSizeStr,
                                    src: basePath + "/general/mana.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* Armor */4 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Armor",
                                    height: iconSizeStr,
                                    src: basePath + "/general/armor.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* MR */5 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Magic resist",
                                    height: iconSizeStr,
                                    src: basePath + "/general/mr.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* AS */6 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Attack speed",
                                    height: iconSizeStr,
                                    src: basePath + "/general/as.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* Star */7 :
                              tmp = React.createElement("span", undefined, "(based on star level)");
                              break;
                          case /* CritDmg */8 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Critical strike damage",
                                    height: iconSizeStr,
                                    src: basePath + "/general/crit_damage.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* CritChance */9 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Critical strike chance",
                                    height: iconSizeStr,
                                    src: basePath + "/general/crit_chance.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* Gold */10 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Gold",
                                    height: iconSizeStr,
                                    src: basePath + "/general/gold.png",
                                    width: iconSizeStr
                                  });
                              break;
                          case /* Range */11 :
                              tmp = React.createElement("img", {
                                    className: "inline-block",
                                    alt: "Range",
                                    height: iconSizeStr,
                                    src: basePath + "/general/range.png",
                                    width: iconSizeStr
                                  });
                              break;
                          
                        }
                        break;
                    case /* TftItemRules */1 :
                        tmp = React.createElement("div", {
                              className: "italic"
                            }, React.createElement(make, {
                                  parts: part._0
                                }));
                        break;
                    case /* Text */3 :
                        tmp = React.createElement("span", undefined, part._0);
                        break;
                    case /* RadiantItemBonus */4 :
                        tmp = React.createElement("span", {
                              className: radiantItemBonus
                            }, React.createElement(make, {
                                  parts: part._0
                                }));
                        break;
                    case /* ShadowItemBonus */5 :
                        tmp = React.createElement("span", {
                              className: shadowItemBonus
                            }, React.createElement(make, {
                                  parts: part._0
                                }));
                        break;
                    case /* ShadowItemPenalty */6 :
                        tmp = React.createElement("span", {
                              className: shadowItemPenalty
                            }, React.createElement(make, {
                                  parts: part._0
                                }));
                        break;
                    case /* ApScaling */7 :
                        tmp = React.createElement("div", {
                              className: CssJs.merge([
                                    "pr-1 inline-flex items-center font-medium",
                                    CssJs.style([
                                          CssJs.color(CssJs.hex(Colors.teal300)),
                                          CssJs.whiteSpace(CssJs.nowrap)
                                        ])
                                  ])
                            }, part._0, React.createElement("span", {
                                  className: CssJs.style([CssJs.maxHeight(CssJs.px(size + 3 | 0))])
                                }, React.createElement("img", {
                                      alt: "Attack damage",
                                      height: iconSizeStr,
                                      src: basePath + "/general/ap.png",
                                      width: iconSizeStr
                                    })));
                        break;
                    case /* ApScalingBase */8 :
                        tmp = React.createElement("div", {
                              className: CssJs.merge([
                                    "inline-flex items-center font-medium",
                                    CssJs.style([
                                          CssJs.color(CssJs.hex(Colors.teal300)),
                                          CssJs.whiteSpace(CssJs.nowrap)
                                        ])
                                  ])
                            }, part._0);
                        break;
                    case /* AdScaling */9 :
                        tmp = React.createElement("div", {
                              className: CssJs.merge([
                                    "inline-flex items-center font-medium",
                                    CssJs.style([CssJs.color(CssJs.hex(Colors.deepOrange300))])
                                  ])
                            }, part._0, React.createElement("span", {
                                  className: CssJs.style([CssJs.maxHeight(CssJs.px(size + 3 | 0))])
                                }, React.createElement("img", {
                                      alt: "Attack damage",
                                      height: iconSizeStr,
                                      src: basePath + "/general/ad.png",
                                      width: iconSizeStr
                                    })));
                        break;
                    case /* AsScaling */10 :
                        tmp = React.createElement("div", {
                              className: CssJs.merge([
                                    "inline-flex items-center font-medium",
                                    CssJs.style([CssJs.color(CssJs.hex(Colors.amber300))])
                                  ])
                            }, part._0, React.createElement("span", {
                                  className: CssJs.style([CssJs.maxHeight(CssJs.px(size + 3 | 0))])
                                }, React.createElement("img", {
                                      alt: "Attack speed",
                                      height: iconSizeStr,
                                      src: basePath + "/general/as.png",
                                      width: iconSizeStr
                                    })));
                        break;
                    case /* Row */11 :
                        tmp = React.createElement("div", {
                              className: CssJs.merge([
                                    "text-white1",
                                    CssJs.style([CssJs.opacity(highlightParts.length === 0 || highlightParts.includes(i) ? 1 : 0.6)])
                                  ])
                            }, React.createElement(make, {
                                  parts: part._0
                                }));
                        break;
                    case /* Bold */12 :
                        tmp = React.createElement("span", {
                              className: "font-medium"
                            }, part._0);
                        break;
                    case /* Italic */2 :
                    case /* KeywordMajor */13 :
                        tmp = React.createElement("i", undefined, part._0);
                        break;
                    case /* DebonairVIP */14 :
                        tmp = React.createElement("div", undefined, part._0);
                        break;
                    
                  }
                }
                return React.createElement("span", {
                            key: String(i),
                            className: CssJs.style([
                                  CssJs.opacity(highlightParts.length === 0 || highlightParts.includes(i) ? 1 : 0.7),
                                  highlightParts.includes(i) ? CssJs.borderBottom(CssJs.px(1), CssJs.solid, CssJs.rgba(255, 255, 255, {
                                              NAME: "num",
                                              VAL: 0.7
                                            })) : CssJs.textDecoration(CssJs.none)
                                ])
                          }, tmp);
              }));
}

export {
  getVariableHashKey ,
  replaceItemVariables ,
  ignoreKeywords ,
  parse ,
  simplifyParts ,
  parseParts ,
  Styles ,
  renderToString ,
  make ,
  
}
/* shadowItemBonus Not a pure module */
