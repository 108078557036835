// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "./Env.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import JsCookie from "js-cookie";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function toUrlName(t) {
  switch (t) {
    case /* Euw */0 :
        return "euw";
    case /* Eune */1 :
        return "eune";
    case /* Na */2 :
        return "na";
    case /* Lan */3 :
        return "lan";
    case /* Las */4 :
        return "las";
    case /* Me */5 :
        return "me";
    case /* Oce */6 :
        return "oce";
    case /* Tr */7 :
        return "tr";
    case /* Ru */8 :
        return "ru";
    case /* Kr */9 :
        return "kr";
    case /* Jp */10 :
        return "jp";
    case /* Br */11 :
        return "br";
    case /* Ph */12 :
        return "ph";
    case /* Sg */13 :
        return "sg";
    case /* Th */14 :
        return "th";
    case /* Tw */15 :
        return "tw";
    case /* Vn */16 :
        return "vn";
    
  }
}

function toName3(t) {
  switch (t) {
    case /* Euw */0 :
        return "EUW";
    case /* Eune */1 :
        return "EUNE";
    case /* Na */2 :
        return "NA";
    case /* Lan */3 :
        return "LAN";
    case /* Las */4 :
        return "LAS";
    case /* Me */5 :
        return "ME";
    case /* Oce */6 :
        return "OCE";
    case /* Tr */7 :
        return "TR";
    case /* Ru */8 :
        return "RU";
    case /* Kr */9 :
        return "KR";
    case /* Jp */10 :
        return "JP";
    case /* Br */11 :
        return "BR";
    case /* Ph */12 :
        return "PH";
    case /* Sg */13 :
        return "SG";
    case /* Th */14 :
        return "TH";
    case /* Tw */15 :
        return "TW";
    case /* Vn */16 :
        return "VN";
    
  }
}

function fromName(t) {
  switch (t) {
    case "BR" :
    case "br" :
        return /* Br */11;
    case "EUNE" :
    case "eune" :
        return /* Eune */1;
    case "EUW" :
    case "euw" :
        return /* Euw */0;
    case "JP" :
    case "jp" :
        return /* Jp */10;
    case "KR" :
    case "kr" :
        return /* Kr */9;
    case "LAN" :
    case "lan" :
        return /* Lan */3;
    case "LAS" :
    case "las" :
        return /* Las */4;
    case "ME" :
    case "me" :
        return /* Me */5;
    case "NA" :
    case "na" :
        return /* Na */2;
    case "OCE" :
    case "oce" :
        return /* Oce */6;
    case "PH" :
    case "ph" :
        return /* Ph */12;
    case "RU" :
    case "ru" :
        return /* Ru */8;
    case "SG" :
    case "sg" :
        return /* Sg */13;
    case "TH" :
    case "th" :
        return /* Th */14;
    case "TR" :
    case "tr" :
        return /* Tr */7;
    case "TW" :
    case "tw" :
        return /* Tw */15;
    case "VN" :
    case "vn" :
        return /* Vn */16;
    default:
      return ;
  }
}

function toApiName(t) {
  switch (t) {
    case /* Euw */0 :
        return "euw1";
    case /* Eune */1 :
        return "eun1";
    case /* Na */2 :
        return "na1";
    case /* Lan */3 :
        return "la1";
    case /* Las */4 :
        return "la2";
    case /* Me */5 :
        return "me1";
    case /* Oce */6 :
        return "oc1";
    case /* Tr */7 :
        return "tr1";
    case /* Ru */8 :
        return "ru";
    case /* Kr */9 :
        return "kr";
    case /* Jp */10 :
        return "jp1";
    case /* Br */11 :
        return "br1";
    case /* Ph */12 :
        return "ph2";
    case /* Sg */13 :
        return "sg2";
    case /* Th */14 :
        return "th2";
    case /* Tw */15 :
        return "tw2";
    case /* Vn */16 :
        return "vn2";
    
  }
}

function fromApiNameSafe(t) {
  switch (t) {
    case "br1" :
        return /* Br */11;
    case "eun1" :
        return /* Eune */1;
    case "euw1" :
        return /* Euw */0;
    case "jp1" :
        return /* Jp */10;
    case "kr" :
        return /* Kr */9;
    case "la1" :
        return /* Lan */3;
    case "la2" :
        return /* Las */4;
    case "me1" :
        return /* Me */5;
    case "na1" :
        return /* Na */2;
    case "oc1" :
        return /* Oce */6;
    case "ph2" :
        return /* Ph */12;
    case "ru" :
        return /* Ru */8;
    case "sg2" :
        return /* Sg */13;
    case "th2" :
        return /* Th */14;
    case "tr1" :
        return /* Tr */7;
    case "tw2" :
        return /* Tw */15;
    case "vn2" :
        return /* Vn */16;
    default:
      return ;
  }
}

function fromApiName(t) {
  var r = fromApiNameSafe(t);
  if (r !== undefined) {
    return r;
  } else {
    return /* Euw */0;
  }
}

var all = [
  /* Br */11,
  /* Eune */1,
  /* Euw */0,
  /* Jp */10,
  /* Kr */9,
  /* Lan */3,
  /* Las */4,
  /* Me */5,
  /* Na */2,
  /* Oce */6,
  /* Ph */12,
  /* Ru */8,
  /* Sg */13,
  /* Th */14,
  /* Tr */7,
  /* Tw */15,
  /* Vn */16
];

function rankedUpdateUtcHour(region) {
  switch (region) {
    case /* Euw */0 :
        return 23;
    case /* Na */2 :
        return 7;
    case /* Lan */3 :
        return 5;
    case /* Eune */1 :
    case /* Me */5 :
        return 22;
    case /* Oce */6 :
        return 14;
    case /* Tr */7 :
    case /* Ru */8 :
        return 21;
    case /* Kr */9 :
    case /* Jp */10 :
        return 15;
    case /* Las */4 :
    case /* Br */11 :
        return 3;
    case /* Ph */12 :
    case /* Sg */13 :
    case /* Th */14 :
    case /* Tw */15 :
    case /* Vn */16 :
        return 17;
    
  }
}

function rankedUpdateUtcHour2(region) {
  switch (region) {
    case /* Euw */0 :
        return 1;
    case /* Na */2 :
        return -7;
    case /* Lan */3 :
        return -5;
    case /* Eune */1 :
    case /* Me */5 :
        return 2;
    case /* Oce */6 :
        return 10;
    case /* Tr */7 :
    case /* Ru */8 :
        return 3;
    case /* Kr */9 :
    case /* Jp */10 :
        return 9;
    case /* Las */4 :
    case /* Br */11 :
        return -3;
    case /* Ph */12 :
    case /* Sg */13 :
    case /* Th */14 :
    case /* Tw */15 :
    case /* Vn */16 :
        return 7;
    
  }
}

function getLanguage(param) {
  var ls = navigator.languages;
  if (ls !== undefined) {
    return Belt_Array.getExn(ls, 0);
  } else {
    return navigator.language;
  }
}

function getInitRegion(param) {
  if (!Env.isBrowser) {
    return /* Euw */0;
  }
  var r = JsCookie.get("player-region");
  if (r !== undefined) {
    return fromApiName(r);
  }
  var match = getLanguage(undefined);
  switch (match) {
    case "en-AU" :
    case "en-NZ" :
        return /* Oce */6;
    case "en-US" :
        return /* Na */2;
    case "ja" :
    case "ja-JP" :
    case "jp" :
        return /* Jp */10;
    case "ko" :
    case "ko-KR" :
    case "kr" :
        return /* Kr */9;
    case "br" :
    case "pt-BR" :
        return /* Br */11;
    case "ru" :
    case "ru-RU" :
        return /* Ru */8;
    case "tr" :
    case "tr-TR" :
        return /* Tr */7;
    default:
      return /* Euw */0;
  }
}

function useRegionSelect(param) {
  var match = React.useState(function () {
        return /* Euw */0;
      });
  var setRegionSelect = match[1];
  if (Env.isBrowser) {
    React.useLayoutEffect((function () {
            Curry._1(setRegionSelect, (function (param) {
                    return getInitRegion(undefined);
                  }));
            
          }), []);
  }
  return [
          match[0],
          setRegionSelect
        ];
}

export {
  toUrlName ,
  toName3 ,
  fromName ,
  toApiName ,
  fromApiNameSafe ,
  fromApiName ,
  all ,
  rankedUpdateUtcHour ,
  rankedUpdateUtcHour2 ,
  getLanguage ,
  getInitRegion ,
  useRegionSelect ,
  
}
/* Env Not a pure module */
