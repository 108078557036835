import { useRouter } from "next/router";
// import { make as Select } from "../components/Select.bs";
import Link from "next/link";

type SecondaryNavProps = {
  items: Array<[string, string]>;
};

const SecondaryNav = ({ items }: SecondaryNavProps) => {
  const router = useRouter();

  return (
    <>
      <div
        className={`md:block fixed z-20 top-12 w-screen h-[44px] left-0 bg-secondary-nav text-[14px] font-montserrat font-medium`}
      >
        <nav className="w-full lg:w-[1153px] mx-auto flex justify-between">
          <div className="hidden md:flex uppercase">
            {items.map(([href, label]) => {
              let isActive = false;
              if (label === "Game Info") {
                isActive = [
                  "/info/units",
                  "/info/traits",
                  "/info/items",
                  "/info/augments",
                  "/info/cheat-sheet",
                  "/info/legends",
                  "/info/regions",
                ].some((v) => router.asPath.startsWith(v));
              } else if (label === "Drop Tables") {
                isActive = ["/info/set-11/tables"].some((v) =>
                  router.asPath.startsWith(v),
                );
              } else {
                isActive =
                  router.asPath.startsWith(href) ||
                  router.asPath.startsWith(href + "/") ||
                  router.asPath.startsWith(href + "?");
              }
              return (
                <Link
                  key={label}
                  prefetch={false}
                  className={"secondary-nav-link" + (isActive ? " active" : "")}
                  href={href}
                >
                  <span className={isActive ? "active" : ""}>{label}</span>
                </Link>
              );
            })}
          </div>
        </nav>
      </div>
      <div className={`md:block h-[44px] w-full`} />
    </>
  );
};

export default SecondaryNav;
