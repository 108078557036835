import dynamic from "next/dynamic";
import { useMemo, useEffect, useCallback, useState } from "react";
import {
  SpotlightProvider,
  openSpotlight,
  registerSpotlightActions,
  removeSpotlightActions,
} from "@mantine/spotlight";
import type { SpotlightAction } from "@mantine/spotlight";
import {
  allUnits,
  allUnits2,
  allTraits,
  traitRankCounts,
  toUrlName,
} from "common/src/tft-static/Sd";
import {
  IconHome,
  IconDashboard,
  IconFileText,
  IconSearch,
} from "@tabler/icons";
import { use as useSet } from "common/src/data/TftSet.bs";
import { useT } from "common/src/bindings/Translation.bs";
import { useRouter } from "next/router";
import type { NextRouter } from "next/router";
import UnitImage from "common/src/tft-static/UnitImage";
import { PlainIcon2 } from "common/src/tft-static/TraitImage";
import ItemImage from "common/src/tft-static/ItemImage";
import AugmentImage from "common/src/tft-static/AugmentImage";
import {
  all as allRegions,
  toUrlName as toRegionName,
  toName3 as toRegionName3,
  fromApiName as fromRegionApiName,
  getInitRegion,
} from "common/src/Region.bs";
import { items, augments } from "common/src/data/Data9.bs";
import { use as useBookmarks } from "../player/PlayerBookmark.bs";
import { allPatches } from "common/src/Config";
import { render as renderPatch } from "common/src/Patch.bs";

const allItemIds = Object.entries(items)
  .filter(([_, v]) => v.id > 8)
  .map(([key, v]) => key);
const allAugmentIds = Object.keys(augments);

const getActions = (
  router: NextRouter,
  set: number,
  unitsT: (id: string) => string,
  traitsT: (id: string) => string,
  itemsT: (id: string) => string,
  augmentsT: (id: string) => string,
): SpotlightAction[] => [
  {
    title: `Team Compositions`,
    onTrigger: () => router.push("/team-compositions"),
  },
  {
    title: `Units Stats`,
    onTrigger: () => router.push("/units"),
  },
  {
    title: `Traits Stats`,
    onTrigger: () => router.push("/traits"),
  },
  {
    title: `Items Stats`,
    onTrigger: () => router.push("/items"),
  },
  {
    title: `Augments Stats`,
    onTrigger: () => router.push("/augments"),
  },
  {
    title: `Stats Explorer`,
    onTrigger: () => router.push("/explorer"),
  },
  {
    title: `Team Builder`,
    onTrigger: () => router.push("/team-builder"),
  },
  {
    title: `Loaded Dice`,
    onTrigger: () => router.push("/loaded-dice"),
  },
  {
    title: `Trends`,
    onTrigger: () => router.push("/trends"),
  },
  {
    title: `Double Up Compositions`,
    onTrigger: () => router.push("/duo-compositions"),
  },
  {
    title: `Hyperroll Compositions`,
    onTrigger: () => router.push("/hyperroll-compositions"),
  },
  {
    title: `Unit Builder`,
    onTrigger: () => router.push("/unit-builder"),
  },
  {
    title: `Perfect Synergies`,
    onTrigger: () => router.push("/perfect-synergies"),
  },
  // {
  //   title: `Set 7 Wrapped`,
  //   onTrigger: () => router.push("/wrapped/set-7"),
  // },
  // {
  //   title: `Set 7.5 Wrapped`,
  //   onTrigger: () => router.push("/wrapped/set-75"),
  // },
  // {
  //   title: `Set 8 Wrapped`,
  //   onTrigger: () => router.push("/wrapped/set-8"),
  // },
  // {
  //   title: `Underground Heist Tables`,
  //   onTrigger: () => router.push("/info/set-8/tables/underground-heist"),
  // },
  // {
  //   title: `Admin Effect Tables`,
  //   onTrigger: () => router.push("/info/set-8/tables/admin"),
  // },
  {
    title: `Units Info`,
    onTrigger: () => router.push("/info/units"),
  },
  {
    title: `Traits Info`,
    onTrigger: () => router.push("/info/traits"),
  },
  {
    title: `Items Info`,
    onTrigger: () => router.push("/info/items"),
  },
  {
    title: `Augments Info`,
    onTrigger: () => router.push("/info/augments"),
  },
  {
    title: `Synergy Grid`,
    onTrigger: () => router.push("/info/cheat-sheet"),
  },
  {
    title: `Pbe Patch Notes`,
    onTrigger: () => router.push("/info/pbe"),
  },
  {
    title: `Patch Notes`,
    onTrigger: () =>
      router.push(
        `/info/patch-notes/${renderPatch(
          allPatches.find((p) => p !== 13250 && p % 10 === 0)!,
        )}`,
      ),
  },
  {
    title: `Global Ranked Leaderboards`,
    onTrigger: () => router.push("/leaderboards"),
  },
  ...allRegions.map((region) => ({
    title: `${toRegionName3(region)} Ranked Leaderboards`,
    onTrigger: () => router.push("/leaderboards/" + toRegionName(region)),
  })),
  {
    title: `Global Hyperroll Leaderboards`,
    onTrigger: () => router.push("/leaderboards/hyperroll"),
  },
  ...allRegions.map((region) => ({
    title: `${toRegionName3(region)} Hyperroll Leaderboards`,
    onTrigger: () =>
      router.push("/leaderboards/hyperroll/" + toRegionName(region)),
  })),
  {
    title: `Global Double Up Leaderboards`,
    onTrigger: () => router.push("/leaderboards/duo"),
  },
  ...allRegions.map((region) => ({
    title: `${toRegionName3(region)} Double Up Leaderboards`,
    onTrigger: () => router.push("/leaderboards/duo/" + toRegionName(region)),
  })),
  {
    title: `Unit Leaderboards`,
    onTrigger: () => router.push("/leaderboards/unit"),
  },
  {
    title: `Bookmark Leaderboards`,
    onTrigger: () => router.push("/leaderboards/bookmarks"),
  },
  ...allUnits2(set).map((unitId) => ({
    title: `${unitsT(unitId)} stats`,
    onTrigger: () => router.push("/unit/" + toUrlName(unitId)),
    icon: <UnitImage unitId={unitId} size={24} />,
  })),
  ...allTraits(set)
    .map((traitId) => {
      const trc = traitRankCounts(traitId, set) || [];
      return trc.map((count, idx) => ({
        title: `${count} ${traitsT(traitId)} stats`,
        onTrigger: () =>
          router.push("/trait/" + toUrlName(traitId) + `/${idx + 1}`),
        icon: <PlainIcon2 traitId={traitId} rank={idx + 1} size={24} />,
      }));
    })
    .flat(),
  ...allItemIds.map((itemId) => ({
    title: `${itemsT(String(itemId))} stats`,
    onTrigger: () => router.push("/item/" + itemId),
    icon: <ItemImage itemId={String(itemId)} size={24} />,
  })),
  ...allAugmentIds.map((augId) => ({
    title: `${augmentsT(String(augId))} stats`,
    onTrigger: () => router.push("/augment/" + augId),
    icon: <AugmentImage augId={String(augId)} size={24} />,
  })),
  ...allUnits2(set).map((unitId) => ({
    title: `${unitsT(unitId)} Leaderboards`,
    onTrigger: () => router.push("/leaderboards/unit/" + toUrlName(unitId)),
    icon: <UnitImage unitId={unitId} size={24} />,
  })),
  ...allUnits(set).map((unitId) => ({
    title: `${unitsT(unitId)} info`,
    onTrigger: () => router.push("/info/units/" + toUrlName(unitId)),
    icon: <UnitImage unitId={unitId} size={24} />,
  })),
  ...allItemIds.map((itemId) => ({
    title: `${itemsT(String(itemId))} info`,
    onTrigger: () => router.push("/info/items/" + itemId),
    icon: <ItemImage itemId={String(itemId)} size={24} />,
  })),
  {
    title: `Home`,
    onTrigger: () => router.push("/"),
  },
];

const getBookmarkActions = (
  bookmarks: Array<any>,
  router: NextRouter,
): SpotlightAction[] => {
  return bookmarks.map((bm) => {
    const region = toRegionName(fromRegionApiName(bm.region));
    return {
      title: `${bm.name} [${region.toUpperCase()}]`,
      onTrigger: () => router.push(`/player/${region}/${bm.name}`),
    };
  });
};

const getRegionOrder = (
  bookmarks: Array<any>,
  initRegion: string,
): Array<string> => {
  const regionCounts: { [region: string]: number } = {
    euw: 0,
    na: 0,
    kr: 0,
    br: 0,
    eune: 0,
    oce: 0,
    jp: 0,
    lan: 0,
    las: 0,
    tr: 0,
    ru: 0,
  };
  bookmarks.forEach((bm) => {
    const region = toRegionName(fromRegionApiName(bm.region));
    regionCounts[region] += 1;
  });
  regionCounts[initRegion] += 0.5;

  const order = Object.entries(regionCounts);
  order.sort(([_r1, v1], [_r2, v2]) => v2 - v1);
  return order.map(([r, _]) => r);
};

const getPlayerSearchActions = (
  regionOrder: Array<string>,
  search: string,
  router: NextRouter,
): SpotlightAction[] => {
  const region = regionOrder[0];
  return [
    {
      id: "search-player",
      title: `Search for player ${search}`,
      onTrigger: () => router.push(`/player/${region}/${search}`),
    },
  ];
};

function CmdPalette() {
  const [search, setSearch] = useState(() => "");

  const [bookmarks] = useBookmarks();
  const initRegion = useMemo(() => toRegionName(getInitRegion()), []);

  const router = useRouter();
  const set = useSet();
  const unitsT = useT(1);
  const traitsT = useT(2);
  const itemsT = useT(3);
  const augmentsT = useT(4);

  const regionOrder = useMemo(
    () => getRegionOrder(bookmarks, initRegion),
    [bookmarks, initRegion],
  );

  const baseActions = useMemo(
    () => getActions(router, set, unitsT, traitsT, itemsT, augmentsT),
    [],
  );

  const bookmarkActions = useMemo(() => {
    return getBookmarkActions(bookmarks, router);
  }, [initRegion, bookmarks]);

  const actions = useMemo(
    () =>
      baseActions
        .concat(bookmarkActions)
        .concat(getPlayerSearchActions(regionOrder, search, router)),
    [bookmarkActions, search],
  );

  useEffect(() => {
    const cb = (e: any) => {
      if (e.ctrlKey && (e.keyCode === 75 || e.keyCode === 80)) {
        openSpotlight();
        e.preventDefault();
      }
    };
    window.document.addEventListener("keydown", cb);
    return () => window.document.removeEventListener("keydown", cb);
  }, []);

  const updateSpotlightAction = useCallback(
    (search: string) => {
      removeSpotlightActions(["search-player"]);
      registerSpotlightActions(
        getPlayerSearchActions(regionOrder, search, router),
      );
    },
    [regionOrder],
  );

  return (
    <SpotlightProvider
      onQueryChange={updateSpotlightAction}
      shortcut={["mod + P", "mod + K"]}
      actions={actions}
      highlightQuery
      searchIcon={<IconSearch size={18} />}
      searchPlaceholder="Go to..."
      nothingFoundMessage="Nothing found..."
      transition={{
        in: { transform: "translateY(0)", opacity: 1 },
        out: { transform: "translateY(-20px)", opacity: 0 },
        transitionProperty: "transform, opacity",
      }}
    />
  );
}

export default dynamic(() => Promise.resolve(CmdPalette), {
  ssr: false,
});
