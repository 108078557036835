// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation2Ts from "./Translation2.ts";

var common = Translation2Ts.common;

var units = Translation2Ts.units;

var traits = Translation2Ts.traits;

var items = Translation2Ts.items;

var augments = Translation2Ts.augments;

export {
  common ,
  units ,
  traits ,
  items ,
  augments ,
  
}
/* common Not a pure module */
