// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";

var common = "bbbbbb";

var uncommon = "14cc73";

var rare = "54c3ff";

var epic = "de0ebd";

var legendary = "ffc430";

function commonAlpha(alpha) {
  return CssJs.rgba(187, 187, 187, {
              NAME: "num",
              VAL: alpha
            });
}

function uncommonAlpha(alpha) {
  return CssJs.rgba(20, 204, 100, {
              NAME: "num",
              VAL: alpha
            });
}

function rareAlpha(alpha) {
  return CssJs.rgba(84, 195, 255, {
              NAME: "num",
              VAL: alpha
            });
}

function epicAlpha(alpha) {
  return CssJs.rgba(222, 14, 189, {
              NAME: "num",
              VAL: alpha
            });
}

function legendaryAlpha(alpha) {
  return CssJs.rgba(255, 196, 48, {
              NAME: "num",
              VAL: alpha
            });
}

function tierColor(cost) {
  switch (cost) {
    case 1 :
        return common;
    case 2 :
        return uncommon;
    case 3 :
        return rare;
    case 4 :
        return epic;
    default:
      return legendary;
  }
}

var commonDesaturated = "bbbbbb";

var uncommonDesaturated = "1fc173";

var rareDesaturated = "5ac0f6";

var epicDesaturated = "d11ab2";

var legendaryDesaturated = "f5bf38";

function commonDesaturatedAlpha(alpha) {
  return CssJs.rgba(187, 187, 187, {
              NAME: "num",
              VAL: alpha
            });
}

function uncommonDesaturatedAlpha(alpha) {
  return CssJs.rgba(91, 193, 115, {
              NAME: "num",
              VAL: alpha
            });
}

function rareDesaturatedAlpha(alpha) {
  return CssJs.rgba(90, 192, 246, {
              NAME: "num",
              VAL: alpha
            });
}

function epicDesaturatedAlpha(alpha) {
  return CssJs.rgba(209, 26, 178, {
              NAME: "num",
              VAL: alpha
            });
}

function legendaryDesaturatedAlpha(alpha) {
  return CssJs.rgba(245, 191, 56, {
              NAME: "num",
              VAL: alpha
            });
}

function commonDesaturatedAlpha2(alpha) {
  return CssJs.rgba(187, 187, 187, {
              NAME: "num",
              VAL: alpha
            });
}

function uncommonDesaturatedAlpha2(alpha) {
  return CssJs.rgba(55, 212, 136, {
              NAME: "num",
              VAL: alpha
            });
}

function rareDesaturatedAlpha2(alpha) {
  return CssJs.rgba(110, 204, 255, {
              NAME: "num",
              VAL: alpha
            });
}

function epicDesaturatedAlpha2(alpha) {
  return CssJs.rgba(220, 56, 195, {
              NAME: "num",
              VAL: alpha
            });
}

function legendaryDesaturatedAlpha2(alpha) {
  return CssJs.rgba(241, 197, 85, {
              NAME: "num",
              VAL: alpha
            });
}

function commonDesaturatedAlpha3(alpha) {
  return CssJs.rgba(187, 187, 187, {
              NAME: "num",
              VAL: alpha
            });
}

function uncommonDesaturatedAlpha3(alpha) {
  return CssJs.rgba(91, 219, 157, {
              NAME: "num",
              VAL: alpha
            });
}

function rareDesaturatedAlpha3(alpha) {
  return CssJs.rgba(135, 213, 255, {
              NAME: "num",
              VAL: alpha
            });
}

function epicDesaturatedAlpha3(alpha) {
  return CssJs.rgba(232, 86, 209, {
              NAME: "num",
              VAL: alpha
            });
}

function legendaryDesaturatedAlpha3(alpha) {
  return CssJs.rgba(255, 214, 110, {
              NAME: "num",
              VAL: alpha
            });
}

function tierDesaturatedColor(cost) {
  switch (cost) {
    case 1 :
        return commonDesaturated;
    case 2 :
        return uncommonDesaturated;
    case 3 :
        return rareDesaturated;
    case 4 :
        return epicDesaturated;
    default:
      return legendaryDesaturated;
  }
}

function tierDesaturatedColorAlpha(cost, alpha) {
  switch (cost) {
    case 1 :
        return commonDesaturatedAlpha(alpha);
    case 2 :
        return uncommonDesaturatedAlpha(alpha);
    case 3 :
        return rareDesaturatedAlpha(alpha);
    case 4 :
        return epicDesaturatedAlpha(alpha);
    default:
      return legendaryDesaturatedAlpha(alpha);
  }
}

function tierDesaturatedColorAlpha2(cost, alpha) {
  switch (cost) {
    case 1 :
        return commonDesaturatedAlpha2(alpha);
    case 2 :
        return uncommonDesaturatedAlpha2(alpha);
    case 3 :
        return rareDesaturatedAlpha2(alpha);
    case 4 :
        return epicDesaturatedAlpha2(alpha);
    default:
      return legendaryDesaturatedAlpha2(alpha);
  }
}

function tierDesaturatedColorAlpha3(cost, alpha) {
  switch (cost) {
    case 1 :
        return commonDesaturatedAlpha3(alpha);
    case 2 :
        return uncommonDesaturatedAlpha3(alpha);
    case 3 :
        return rareDesaturatedAlpha3(alpha);
    case 4 :
        return epicDesaturatedAlpha3(alpha);
    default:
      return legendaryDesaturatedAlpha3(alpha);
  }
}

function blueGrey500Alpha(alpha) {
  return CssJs.rgba(96, 125, 139, {
              NAME: "num",
              VAL: alpha
            });
}

function teal200Alpha(alpha) {
  return CssJs.rgba(128, 203, 196, {
              NAME: "num",
              VAL: alpha
            });
}

var accent = "6e56cf";

var accent5 = "32275f";

var accent6 = "392c72";

var accent7 = "443592";

var accent8 = "5842c3";

var accent9 = "6e56cf";

var primaryMui = "#52a9ff";

var accentMui = "#5842c3";

var primaryMuiLight = "#006adc";

var accentMuiLight = "#aa99ec";

var background = "305465";

var brown8 = "#775940";

var red8 = "#aa2429";

var amberLight8 = "#ee9d2b";

var radiant = "eddd6b";

var uncommonDesaturated2 = "37D488";

var uncommonDesaturated3 = "5BDB9D";

var rareDesaturated2 = "6ECCFF";

var rareDesaturated3 = "87D5FF";

var epicDesaturated2 = "DC38C3";

var epicDesaturated3 = "E856D1";

var legendaryDesaturated2 = "F1C555";

var legendaryDesaturated3 = "FFD66E";

var teal200 = "80CBC4";

var deepPurple200 = "b39ddb";

var blue200 = "90caf9";

var lightBlue200 = "81d4fa";

var green200 = "a5d6a7";

var red300 = "E57373";

var pink300 = "F06292";

var purple300 = "BA68C8";

var deepPurple300 = "9575CD";

var indigo300 = "7986CB";

var cyan300 = "4DD0E1";

var teal300 = "4DB6AC";

var green300 = "81C784";

var lightGreen300 = "AED581";

var lime300 = "DCE775";

var amber300 = "FFD54F";

var deepOrange300 = "FF8A65";

var brown300 = "A1887F";

var blueGray300 = "90A4AE";

var challenger = "ed6767";

var grandMaster = "c440da";

var master = "5b4ba5";

var diamond = "5393ca";

var platinum = "12a594";

var emerald = "11b288";

var gold = "ffb93b";

var silver = "92b1bd";

var bronze = "ca9372";

var iron = "505050";

var challenger2 = "F5D426";

var grandmaster2 = "CE4039";

var master2 = "ED5EBA";

var diamond2 = "6D9DFF";

export {
  accent ,
  accent5 ,
  accent6 ,
  accent7 ,
  accent8 ,
  accent9 ,
  primaryMui ,
  accentMui ,
  primaryMuiLight ,
  accentMuiLight ,
  background ,
  brown8 ,
  red8 ,
  amberLight8 ,
  common ,
  uncommon ,
  rare ,
  epic ,
  legendary ,
  radiant ,
  commonAlpha ,
  uncommonAlpha ,
  rareAlpha ,
  epicAlpha ,
  legendaryAlpha ,
  uncommonDesaturated2 ,
  uncommonDesaturated3 ,
  rareDesaturated2 ,
  rareDesaturated3 ,
  epicDesaturated2 ,
  epicDesaturated3 ,
  legendaryDesaturated2 ,
  legendaryDesaturated3 ,
  tierColor ,
  commonDesaturated ,
  uncommonDesaturated ,
  rareDesaturated ,
  epicDesaturated ,
  legendaryDesaturated ,
  commonDesaturatedAlpha ,
  uncommonDesaturatedAlpha ,
  rareDesaturatedAlpha ,
  epicDesaturatedAlpha ,
  legendaryDesaturatedAlpha ,
  commonDesaturatedAlpha2 ,
  uncommonDesaturatedAlpha2 ,
  rareDesaturatedAlpha2 ,
  epicDesaturatedAlpha2 ,
  legendaryDesaturatedAlpha2 ,
  commonDesaturatedAlpha3 ,
  uncommonDesaturatedAlpha3 ,
  rareDesaturatedAlpha3 ,
  epicDesaturatedAlpha3 ,
  legendaryDesaturatedAlpha3 ,
  tierDesaturatedColor ,
  tierDesaturatedColorAlpha ,
  tierDesaturatedColorAlpha2 ,
  tierDesaturatedColorAlpha3 ,
  teal200 ,
  deepPurple200 ,
  blue200 ,
  lightBlue200 ,
  green200 ,
  red300 ,
  pink300 ,
  purple300 ,
  deepPurple300 ,
  indigo300 ,
  cyan300 ,
  teal300 ,
  green300 ,
  lightGreen300 ,
  lime300 ,
  amber300 ,
  deepOrange300 ,
  brown300 ,
  blueGray300 ,
  blueGrey500Alpha ,
  teal200Alpha ,
  challenger ,
  grandMaster ,
  master ,
  diamond ,
  platinum ,
  emerald ,
  gold ,
  silver ,
  bronze ,
  iron ,
  challenger2 ,
  grandmaster2 ,
  master2 ,
  diamond2 ,
  
}
/* CssJs Not a pure module */
