// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EnvBindJs from "./Env.bind.js";

var isProd = EnvBindJs.isProd;

var isBrowser = EnvBindJs.isBrowser;

export {
  isProd ,
  isBrowser ,
  
}
/* isProd Not a pure module */
