// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AmplitudeBindJs from "./Amplitude.bind.js";

function log(prim) {
  AmplitudeBindJs.log(prim);
  
}

function log2(prim0, prim1) {
  AmplitudeBindJs.log(prim0, prim1);
  
}

function setUserId(prim) {
  AmplitudeBindJs.setUserId(prim);
  
}

export {
  log ,
  log2 ,
  setUserId ,
  
}
/* ./Amplitude.bind.js Not a pure module */
