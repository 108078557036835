// Generated by ReScript, PLEASE EDIT WITH CARE


var set = ((k, v) => localStorage.setItem(k, v));

var get = (k => localStorage.getItem(k));

export {
  set ,
  get ,
  
}
/* No side effect */
