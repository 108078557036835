let data56;
if (typeof window === "undefined") {
  data56 = require("../static-s56/Data.json");
} else {
  // data56 = require("../static-s56/Data.json");
  // window.data56 = data56;
  data56 = window.data56;
}

export { data56 };
