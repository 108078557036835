// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Route from "../Route.bs.js";
import * as React from "react";
import * as Config from "common/src/Config.bs.js";
import * as Router from "common/src/Router.bs.js";
import * as Aperture from "common/src/Aperture.bs.js";
import * as GameType from "common/src/GameType.bs.js";
import * as RankGroup from "common/src/RankGroup.bs.js";
import JsCookie from "js-cookie";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var apertureValueContext = React.createContext(undefined);

var apertureSetterContext = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = apertureValueContext.Provider;

var make$1 = React.memo(make);

var ValueProvider = {
  makeProps: makeProps,
  make: make$1
};

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make$2 = apertureSetterContext.Provider;

var make$3 = React.memo(make$2);

var SetterProvider = {
  makeProps: makeProps$1,
  make: make$3
};

function ApertureCtx$Provider(Props) {
  var children = Props.children;
  var router = Router.use(undefined);
  var match = React.useState(function () {
        var route = Route.fromAsPath(router.asPath);
        if (typeof route !== "number" && route.TAG === /* Stats */0) {
          var match = route._0;
          if (typeof match === "number") {
            match === /* Explorer */0;
          } else {
            switch (match.TAG | 0) {
              case /* Item */6 :
              case /* Augment */7 :
                  return match.aperture;
              case /* Unit */4 :
              case /* Trait */5 :
              case /* ExaltedDetails */9 :
                  return match.aperture;
              case /* HrComps */11 :
              case /* DuoComps */12 :
                  break;
              default:
                return match.aperture;
            }
          }
        }
        var defrg = JsCookie.get("defrg");
        var rankGroup = defrg !== undefined ? Belt_Option.getWithDefault(RankGroup.fromId(defrg), /* Diamond */1) : /* Diamond */1;
        return {
                patch: /* Patch */{
                  _0: Config.lastPatch
                },
                rankGroup: rankGroup,
                gameType: /* Normal */0,
                queue: 1100
              };
      });
  var setValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          JsCookie.set("rank-group", RankGroup.toId(value.rankGroup));
          
        }), [value]);
  var setAperture = function (cb) {
    return Curry._1(setValue, (function (value) {
                  var aperture = Aperture.validate(Curry._1(cb, value));
                  JsCookie.set("defrg", RankGroup.toId(aperture.rankGroup));
                  var currUrl = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
                  var params = Route.fromPath(currUrl.path, currUrl.search);
                  var newRoute;
                  if (typeof params === "number") {
                    newRoute = params;
                  } else {
                    switch (params.TAG | 0) {
                      case /* Stats */0 :
                          var params$1 = params._0;
                          if (typeof params$1 === "number") {
                            newRoute = params;
                          } else {
                            switch (params$1.TAG | 0) {
                              case /* Units */0 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Units */0,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Traits */1 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Traits */1,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Items */2 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Items */2,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Augments */3 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Augments */3,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Unit */4 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Unit */4,
                                      unit: params$1.unit,
                                      starLevel: params$1.starLevel,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Trait */5 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Trait */5,
                                      trait: params$1.trait,
                                      rank: params$1.rank,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Item */6 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Item */6,
                                      id: params$1.id,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Augment */7 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Augment */7,
                                      id: params$1.id,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Exalted */8 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Exalted */8,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* ExaltedDetails */9 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* ExaltedDetails */9,
                                      gameType: params$1.gameType,
                                      tier: params$1.tier,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* TeamCompositions2 */10 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* TeamCompositions2 */10,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              case /* Trends */13 :
                                  newRoute = {
                                    TAG: /* Stats */0,
                                    _0: {
                                      TAG: /* Trends */13,
                                      aperture: aperture
                                    }
                                  };
                                  break;
                              default:
                                newRoute = params;
                            }
                          }
                          break;
                      case /* TempComps */1 :
                          newRoute = {
                            TAG: /* TempComps */1,
                            aperture: aperture
                          };
                          break;
                      default:
                        newRoute = params;
                    }
                  }
                  router.replace(Route.toString(newRoute));
                  return aperture;
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(make$1, makeProps(value, React.createElement(make$3, makeProps$1(setAperture, children, undefined)), undefined)));
}

var Provider = {
  make: ApertureCtx$Provider
};

function ApertureCtx$PlaceholderProvider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return Aperture.$$default;
      });
  var setAperture = function (prim) {
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement(make$1, makeProps(match[0], React.createElement(make$3, makeProps$1(setAperture, children, undefined)), undefined)));
}

var PlaceholderProvider = {
  make: ApertureCtx$PlaceholderProvider
};

function useValue(param) {
  return React.useContext(apertureValueContext);
}

function useSetter(param) {
  return React.useContext(apertureSetterContext);
}

function getPrefix(v) {
  var patch = v.patch;
  return "tft/" + (
          patch ? String(patch._0) : "latest"
        ) + "/" + RankGroup.toId(v.rankGroup) + "/" + GameType.toId(v.gameType);
}

function usePrefix(param) {
  return getPrefix(React.useContext(apertureValueContext));
}

export {
  apertureValueContext ,
  apertureSetterContext ,
  ValueProvider ,
  SetterProvider ,
  Provider ,
  PlaceholderProvider ,
  useValue ,
  useSetter ,
  getPrefix ,
  usePrefix ,
  
}
/* apertureValueContext Not a pure module */
