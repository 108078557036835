// Generated by ReScript, PLEASE EDIT WITH CARE

import ConsentsTsx from "./Consents.tsx";

var make = ConsentsTsx;

export {
  make ,
  
}
/* make Not a pure module */
