let data9;
if (typeof window === "undefined") {
  data9 = require("../static-s9/Data.json");
} else {
  // data9 = require("../static-s9/Data.json");
  // window.data9 = data9;
  data9 = window.data9;
}

export { data9 };
