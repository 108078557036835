const EMPTY = Symbol("e");

export const make = (cb) => {
  return [EMPTY, cb];
};

export const get = (cell) => {
  if (cell[0] === EMPTY) {
    const v = cell[1]();
    cell[0] = v;
    return v;
  } else {
    return cell[0];
  }
};
