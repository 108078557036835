let data12;
if (typeof window === "undefined") {
  data12 = require("../static-s12/Data.json");
} else {
  // data12 = require("../static-s12/Data.json");
  // window.data12 = data12;
  data12 = window.data12;
}

export { data12 };
