// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Translation2 from "./Translation2.bs.js";

var translationContext = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = translationContext.Provider;

var make$1 = React.memo(make);

var InnerProvider = {
  makeProps: makeProps,
  make: make$1
};

function Translation(Props) {
  var initialLocale = Props.initialLocale;
  var children = Props.children;
  var match = React.useState(function () {
        return {
                locale: initialLocale
              };
      });
  return React.createElement(make$1, makeProps(match[0], children, undefined));
}

function useT(typ) {
  switch (typ) {
    case /* Common */0 :
        return Translation2.common;
    case /* Units */1 :
        return Translation2.units;
    case /* Traits */2 :
        return Translation2.traits;
    case /* Items */3 :
        return Translation2.items;
    case /* Augments */4 :
        return Translation2.augments;
    
  }
}

function useT2(typ) {
  if (typ === "traits") {
    return Translation2.traits;
  } else if (typ === "common") {
    return Translation2.common;
  } else if (typ === "augments") {
    return Translation2.augments;
  } else if (typ === "units") {
    return Translation2.units;
  } else {
    return Translation2.items;
  }
}

function useLocale() {
  return React.useContext(translationContext).locale;
}

var make$2 = Translation;

export {
  translationContext ,
  InnerProvider ,
  make$2 as make,
  useT ,
  useT2 ,
  useLocale ,
  
}
/* translationContext Not a pure module */
