import { useEffect } from "react";

export const useSticky = (onIsSticky) => {
  useEffect(() => {
    var observer = new IntersectionObserver(
      function (entries) {
        // no intersection
        if (entries[0].intersectionRatio === 0) {
          onIsSticky(true);
          document.querySelector("#nav-container").classList.add("stuck");
        }
        // fully intersects
        else if (entries[0].intersectionRatio === 1) {
          onIsSticky(false);
          document.querySelector("#nav-container").classList.remove("stuck");
        }
      },
      {
        threshold: [0, 1],
      }
    );

    observer.observe(document.querySelector("#nav-container-top"));
  }, []);
};
