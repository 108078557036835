import { use as useScreenAtom } from "common/src/atoms/ScreenAtom.bs";
import { FormControl, Select, MenuItem } from "@mui/material";

type Props<V> = {
  disabled?: boolean;
  options: Array<[V, String]>;
  value: V;
  setValue: (v: V) => void;
  toKey: (v: V) => string;
  fromKey: (str: string) => V;
  className?: string;
  classes?: any;
  label?: string;
};

export default function SelectWrap<T>({
  disabled,
  options,
  value,
  setValue,
  toKey,
  fromKey,
  className,
  classes,
}: Props<T>) {
  const screenSize = useScreenAtom();

  return (
    <FormControl disabled={disabled}>
      <Select
        variant="standard"
        native={
          // For xs and sm
          screenSize > 3
        }
        value={toKey(value)}
        onChange={(e) => {
          let v = e["target"]["value"];
          setValue(fromKey(v));
        }}
        className={className}
        classes={classes}
      >
        {screenSize > 3
          ? options.map(([v, label]) => (
              <option key={toKey(v)} value={toKey(v)}>
                {label}
              </option>
            ))
          : options.map(([v, label]) => (
              <MenuItem key={toKey(v)} value={toKey(v)}>
                {label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}
