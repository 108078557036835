// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RitoDescription2Tsx from "./RitoDescription2.tsx";

function parseDesc(prim) {
  return RitoDescription2Tsx.parseDesc(prim);
}

function diffParts(prim0, prim1) {
  return RitoDescription2Tsx.diffParts(prim0, prim1);
}

var make = RitoDescription2Tsx.Desc;

var make$1 = RitoDescription2Tsx.DescParts;

var Parts = {
  make: make$1
};

export {
  parseDesc ,
  diffParts ,
  make ,
  Parts ,
  
}
/* make Not a pure module */
