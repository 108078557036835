export const theme = {
  colorScheme: "dark",
  // white: "rgba(255, 255, 255, 0.87)",
  // black: "rgba(0, 0, 0, 0.87)",
  colors: {
    brand: [
      "var(--primary-1)",
      "var(--primary-2)",
      "var(--primary-3)",
      "var(--primary-4)",
      "var(--primary-5)",
      "var(--primary-6)",
      "var(--primary-7)",
      "var(--primary-8)",
      "var(--primary-9)",
      "var(--primary-10)",
    ],
    dark: [
      "var(--primary-12)",
      "var(--primary-10)",
      "var(--primary-8)",
      "var(--primary-7)",
      "var(--primary-6)",
      "var(--primary-5)",
      "var(--primary-4)",
      "var(--primary-3)",
      "var(--primary-2)",
      "var(--primary-1)",
    ],
  },
  primaryColor: "brand",
  // fontFamily: "Roboto",
};

export const midnightTheme = {
  colorScheme: "dark",
  // white: "rgba(255, 255, 255, 0.87)",
  // black: "rgba(0, 0, 0, 0.87)",
  colors: {
    brand: [
      "var(--primary-1)",
      "var(--primary-2)",
      "var(--primary-3)",
      "var(--primary-4)",
      "var(--primary-5)",
      "var(--primary-6)",
      "var(--primary-7)",
      "var(--primary-8)",
      "var(--primary-9)",
      "var(--primary-10)",
    ],
    dark: [
      "var(--primary-12)",
      "var(--primary-10)",
      "var(--primary-9)",
      "var(--primary-8)",
      "var(--primary-6)",
      "var(--primary-4)",
      "var(--primary-2)",
      "var(--primary-2)",
      "var(--primary-1)",
      "var(--primary-1)",
    ],
  },
  primaryColor: "brand",
  // fontFamily: "Roboto",
};

export const lightTheme = {
  colorScheme: "light",
  // white: "rgba(255, 255, 255, 0.87)",
  // black: "rgba(0, 0, 0, 0.87)",
  colors: {
    brand: [
      "var(--primary-1)",
      "var(--primary-2)",
      "var(--primary-3)",
      "var(--primary-4)",
      "var(--primary-5)",
      "var(--primary-6)",
      "var(--primary-7)",
      "var(--primary-8)",
      "var(--primary-9)",
      "var(--primary-10)",
    ],
    dark: [
      "var(--primary-12)",
      "var(--primary-10)",
      "var(--primary-8)",
      "var(--primary-7)",
      "var(--primary-6)",
      "var(--primary-5)",
      "var(--primary-4)",
      "var(--primary-3)",
      "var(--primary-2)",
      "var(--primary-1)",
    ],
  },
  primaryColor: "brand",
  // fontFamily: "Roboto",
};
