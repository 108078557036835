let data10;
if (typeof window === "undefined") {
  data10 = require("../static-s10/Data.json");
} else {
  // data10 = require("../static-s10/Data.json");
  // window.data10 = data10;
  data10 = window.data10;
}

export { data10 };
