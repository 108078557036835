// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SettingsCfgTsx from "./SettingsCfg.tsx";

function init(prim) {
  SettingsCfgTsx.init_cfg();
  
}

export {
  init ,
  
}
/* ./SettingsCfg.tsx Not a pure module */
