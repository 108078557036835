import { useEffect } from "react";

const loadConstentBox = () => {
  //@ts-ignore
  document.getElementById("consent-box").style.display = window["__tcfapi"]
    ? ""
    : "none";
};

export default function () {
  useEffect(() => {
    //@ts-ignore
    if (window["nitroAds"] && window["nitroAds"].loaded) {
      loadConstentBox();
    } else {
      document.addEventListener("nitroAds.loaded", () => loadConstentBox());
    }
  });

  return (
    // className="bg-bg2 p-2 flex-grow-0 rounded border cursor-pointer border-primary-7"
    <div
      className="cursor-pointer"
      id="consent-box"
      style={{ display: "none" }}
    >
      <div
        onClick={() => {
          // @ts-ignore
          if (window.__cmp) {
            // @ts-ignore
            window.__cmp("showModal");
          }
        }}
      >
        Manage Consents
      </div>
    </div>
  );
}
