// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var isBrowser = (() => typeof window !== "undefined");

function $percent(a, b) {
  if (b === 0) {
    return 0;
  } else {
    return a / b;
  }
}

function $pipe$pipe$pipe(o1, o2) {
  if (o1 !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(o1));
  } else {
    return o2;
  }
}

var $pipe$pipe$eq = Belt_Option.getWithDefault;

function formatPct(digitsOpt, f) {
  var digits = digitsOpt !== undefined ? digitsOpt : 1;
  var pct = f * 100;
  if (f === 0) {
    return "0";
  } else if (f === 1) {
    return "100";
  } else if (f < 0.1) {
    return pct.toFixed(digits + 1 | 0);
  } else {
    return pct.toFixed(digits);
  }
}

function formatPct2(digits, f) {
  return formatPct(digits, f) + "%";
}

function formatPct3(f) {
  if (f >= 10) {
    return f.toFixed(1) + "%";
  } else {
    return f.toFixed(2) + "%";
  }
}

function f(prim) {
  return prim;
}

function toFixed(digitsOpt, f) {
  var digits = digitsOpt !== undefined ? digitsOpt : 2;
  return f.toFixed(digits);
}

function toFixedSign(digitsOpt, f) {
  var digits = digitsOpt !== undefined ? digitsOpt : 2;
  return (
          f > 0 ? "+" : ""
        ) + toFixed(digits, f);
}

function toFixedSmartInner(f, _digits) {
  while(true) {
    var digits = _digits;
    var mult = Math.pow(10, digits);
    var rf = Math.round(f * mult) / mult;
    if (digits >= 4) {
      return rf.toFixed(4);
    }
    if (Math.abs(rf - f) < 1 / mult / 20) {
      return rf.toFixed(digits);
    }
    _digits = digits + 1 | 0;
    continue ;
  };
}

function toFixedSmart(f) {
  return toFixedSmartInner(f, 0);
}

function formatPctSmart(f) {
  return toFixedSmartInner(f * 100, 0) + "%";
}

function s(s$1) {
  return s$1;
}

function c(strs) {
  return strs.join(" ");
}

export {
  isBrowser ,
  $percent ,
  $pipe$pipe$pipe ,
  $pipe$pipe$eq ,
  formatPct ,
  formatPct2 ,
  formatPct3 ,
  f ,
  toFixed ,
  toFixedSign ,
  toFixedSmartInner ,
  toFixedSmart ,
  formatPctSmart ,
  s ,
  c ,
  
}
/* No side effect */
