// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "common/src/Env.bs.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LocalStorage from "common/src/LocalStorage.bs.js";

function use(param) {
  if (Env.isBrowser) {
    return React.useState(function () {
                  return Caml_obj.caml_equal(Caml_option.nullable_to_opt(LocalStorage.get("afmt")), "yay");
                })[0];
  } else {
    return false;
  }
}

export {
  use ,
  
}
/* Env Not a pure module */
