// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lzy from "../util/Lzy.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Data9BindJs from "./Data9.bind.js";

var data = Data9BindJs.data9;

var units = data.units;

var traits = data.traits;

var items = data.items;

var augments = data.augments;

var hrAugments = data.hrAugments;

var legendAugments = data.legendAugments;

var legends = data.legends;

var itemsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(items), (function (param) {
              var v = param[1];
              arr[v.id] = [
                param[0],
                v
              ];
              
            }));
      return arr;
    });

function itemsArr() {
  return Lzy.get(itemsArr_);
}

var augmentsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(augments), (function (param) {
              var v = param[1];
              arr[v.id] = [
                param[0],
                v
              ];
              
            }));
      return arr;
    });

function augmentsArr() {
  return Lzy.get(augmentsArr_);
}

function gridItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var y$1 = match[1];
  var x$1 = match[0];
  if (y$1 === 9) {
    if (x$1 === 9) {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45);
    } else {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45 + x$1 | 0);
    }
  } else {
    return Belt_Array.getExn(Lzy.get(itemsArr_), (((1 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + y$1 | 0);
  }
}

function gridRadiantItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var x$1 = match[0];
  return Belt_Array.getExn(Lzy.get(itemsArr_), (((60 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + match[1] | 0);
}

function getItemTyp(id) {
  if (id < 45) {
    return "Craftable";
  } else if (id <= 53) {
    return "CraftableEmblem";
  } else if (id < 68) {
    return "Ornn";
  } else if (id < 104) {
    return "Radiant";
  } else if (id < 117) {
    return "ElusiveEmblem";
  } else {
    return "Other";
  }
}

var ornnItems = [
  "4OrnnAnimaVisage",
  "4OrnnDeathsDefiance",
  "4OrnnEternalWinter",
  "4OrnnInfinityForce",
  "4OrnnMuramana",
  "4OrnnObsidianCleaver",
  "4OrnnRanduinsSanctum",
  "4OrnnTheCollector",
  "4OrnnZhonyasParadox",
  "9OrnnHullbreaker",
  "9OrnnTrickstersGlass",
  "9OrnnHorizonFocus",
  "9OrnnDeathfireGrasp",
  "9OrnnPrototypeForge"
];

var craftableSpats = [
  "9IoniaEmblem",
  "9ChallengerEmblem",
  "9ShurimaEmblem",
  "9SorcererEmblem",
  "9ArmorcladEmblem",
  "9DemaciaEmblem",
  "9NoxusEmblem",
  "9SlayerEmblem"
];

var elusiveSpats = [
  "9BastionEmblem",
  "9BrawlerEmblem",
  "9DeadeyeEmblem",
  "9FreljordEmblem",
  "9TrickshotEmblem",
  "9PreserverEmblem",
  "9PiltoverEmblem",
  "9RogueEmblem",
  "9ShadowIslesEmblem",
  "9StrategistEmblem",
  "9TargonEmblem",
  "9VoidEmblem",
  "9ZaunEmblem"
];

var shimmerscaleItems = [
  "7ShimmerscaleGoldmancersStaff",
  "7ShimmerscaleMogulsMail",
  "7ShimmerscaleHeartOfGold",
  "7ShimmerscaleDravensAxe",
  "7ShimmerscaleGamblersBlade",
  "7ShimmerscaleDeterminedInvestor",
  "7ShimmerscaleDiamondHands"
];

var zaunMods = [
  "9Chempunk_BloodRageMod",
  "9Chempunk_VirulentBiowareMod",
  "9Chempunk_UnstableChemtankMod",
  "9Chempunk_RoboticArmMod",
  "9Chempunk_ChemtankMod",
  "9Chempunk_AdaptiveImplantMod"
];

var heimerUpgrades = [
  "TFT9_HeimerUpgrade_SelfRepair",
  "TFT9_HeimerUpgrade_MicroRockets",
  "TFT9_HeimerUpgrade_ShrinkRay",
  "TFT9_HeimerUpgrade_Goldification"
];

function getRecommendedItems(typ) {
  switch (typ) {
    case "Attack Carry" :
        return [
                "GuinsoosRageblade",
                "MadredsBloodrazor",
                "Deathblade",
                "InfinityEdge",
                "RunaansHurricane",
                "LastWhisper",
                "PowerGauntlet",
                "GuardianAngel"
              ];
    case "Attack Caster" :
        return [
                "PowerGauntlet",
                "Deathblade",
                "InfinityEdge",
                "LastWhisper",
                "MadredsBloodrazor",
                "Bloodthirster",
                "SpearOfShojin",
                "GuardianAngel"
              ];
    case "Attack Fighter" :
        return [
                "Bloodthirster",
                "UnstableConcoction",
                "LastWhisper",
                "MadredsBloodrazor",
                "PowerGauntlet",
                "TitansResolve",
                "HextechGunblade",
                "Quicksilver"
              ];
    case "Attack Reaper" :
        return [
                "Deathblade",
                "MadredsBloodrazor",
                "LastWhisper",
                "GuardianAngel",
                "UnstableConcoction",
                "InfinityEdge",
                "RunaansHurricane",
                "Bloodthirster"
              ];
    case "Attack Tank" :
        return [
                "DragonsClaw",
                "WarmogsArmor",
                "BrambleVest",
                "GargoyleStoneplate",
                "RedBuff",
                "Redemption",
                "FrozenHeart",
                "TitansResolve"
              ];
    case "Magic Carry" :
        return [
                "GuinsoosRageblade",
                "JeweledGauntlet",
                "ArchangelsStaff",
                "RabadonsDeathcap",
                "MadredsBloodrazor",
                "StatikkShiv",
                "PowerGauntlet",
                "HextechGunblade"
              ];
    case "Magic Caster" :
        return [
                "SeraphsEmbrace",
                "ArchangelsStaff",
                "RabadonsDeathcap",
                "JeweledGauntlet",
                "MadredsBloodrazor",
                "HextechGunblade",
                "SpearOfShojin",
                "Morellonomicon"
              ];
    case "Magic Fighter" :
        return [
                "HextechGunblade",
                "ArchangelsStaff",
                "JeweledGauntlet",
                "TitansResolve",
                "UnstableConcoction",
                "IonicSpark",
                "Morellonomicon",
                "PowerGauntlet"
              ];
    case "Magic Reaper" :
        return [
                "RabadonsDeathcap",
                "JeweledGauntlet",
                "SpearOfShojin",
                "IonicSpark",
                "MadredsBloodrazor",
                "UnstableConcoction",
                "HextechGunblade",
                "GuinsoosRageblade"
              ];
    case "Magic Tank" :
        return [
                "DragonsClaw",
                "RedBuff",
                "GargoyleStoneplate",
                "BrambleVest",
                "WarmogsArmor",
                "Redemption",
                "Morellonomicon",
                "FrozenHeart"
              ];
    default:
      return [];
  }
}

export {
  data ,
  units ,
  traits ,
  items ,
  augments ,
  hrAugments ,
  legendAugments ,
  legends ,
  itemsArr_ ,
  itemsArr ,
  augmentsArr_ ,
  augmentsArr ,
  gridItem ,
  gridRadiantItem ,
  getItemTyp ,
  ornnItems ,
  craftableSpats ,
  elusiveSpats ,
  shimmerscaleItems ,
  zaunMods ,
  heimerUpgrades ,
  getRecommendedItems ,
  
}
/* data Not a pure module */
