// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var get = Js_dict.get;

function getExn(v, key) {
  return Belt_Option.getExn(Js_dict.get(v, key));
}

var geti = Js_dict.get;

function getiExn(v, key) {
  return Belt_Option.getExn(Js_dict.get(v, key));
}

function getDef(v, def, key) {
  return Belt_Option.getWithDefault(Js_dict.get(v, key), def);
}

export {
  get ,
  getExn ,
  geti ,
  getiExn ,
  getDef ,
  
}
/* No side effect */
