// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../Env.bs.js";
import * as Jotai from "jotai";
import * as LocalStorage from "../LocalStorage.bs.js";

var tmp;

if (Env.isBrowser) {
  var match = LocalStorage.get("theme");
  if (match == null) {
    tmp = "dark";
  } else {
    switch (match) {
      case "dark" :
          tmp = "dark";
          break;
      case "light" :
          tmp = "light";
          break;
      case "midnight" :
          tmp = "midnight";
          break;
      default:
        tmp = "dark";
    }
  }
} else {
  tmp = "dark";
}

var darkTheme = Jotai.atom(tmp);

function useTheme(param) {
  return Jotai.useAtom(darkTheme)[0];
}

export {
  darkTheme ,
  useTheme ,
  
}
/* darkTheme Not a pure module */
