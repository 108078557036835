// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mui from "common/src/Mui.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Route from "../Route.bs.js";
import * as Jotai from "jotai";
import * as React from "react";
import * as Router from "common/src/Router.bs.js";
import * as CdnAtom from "common/src/atoms/CdnAtom.bs.js";
import * as Mantine from "../bindings/Mantine.bs.js";
import * as Consents from "../Consents.bs.js";
import * as SidebarAd from "../ads/SidebarAd.bs.js";
import * as ThemeAtom from "common/src/atoms/ThemeAtom.bs.js";
import * as AppHeader2 from "../header/AppHeader2.bs.js";
import * as CmdPalette from "../cmd-palette/CmdPalette.bs.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as AppBoundary from "common/src/AppBoundary.bs.js";
import * as SettingsCfg from "common/src/SettingsCfg.bs.js";
import * as LocalStorage from "common/src/LocalStorage.bs.js";
import * as Core from "@mantine/core";
import * as Styles from "@mui/material/styles";

function AppLayout(Props) {
  var children = Props.children;
  var match = Jotai.useAtom(ThemeAtom.darkTheme);
  var setTheme = match[1];
  var theme = match[0];
  React.useEffect((function () {
          var match = LocalStorage.get("theme");
          if (!(match == null)) {
            switch (match) {
              case "dark" :
                  Curry._1(setTheme, "dark");
                  break;
              case "light" :
                  Curry._1(setTheme, "light");
                  break;
              case "midnight" :
                  Curry._1(setTheme, "midnight");
                  break;
              default:
                
            }
          }
          
        }), []);
  var muiTheme = React.useMemo((function () {
          if (theme === "light") {
            return Mui.lightTheme;
          } else if (theme === "midnight") {
            return Mui.midnightTheme;
          } else {
            return Mui.mainTheme;
          }
        }), [theme]);
  var mantineTheme = React.useMemo((function () {
          if (theme === "light") {
            return Mantine.lightTheme;
          } else if (theme === "midnight") {
            return Mantine.midnightTheme;
          } else {
            return Mantine.theme;
          }
        }), [theme]);
  CdnAtom.useAvifSupportSetter();
  var router = Router.use(undefined);
  var match$1 = React.useState(function () {
        return Route.fromAsPath(router.asPath);
      });
  var activeRoute = match$1[0];
  ScreenAtom.useSetter(undefined);
  CdnAtom.useSetter(undefined);
  CdnAtom.useSetter3(undefined);
  SettingsCfg.init(undefined);
  var el = React.createElement(Core.MantineProvider, {
        theme: mantineTheme,
        children: React.createElement(Styles.ThemeProvider, {
              theme: muiTheme,
              children: React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "flex flex-col justify-between min-h-screen"
                      }, React.createElement("div", {
                            className: "flex justify-center pl-[calc(100vw-100%)]"
                          }, React.createElement("div", {
                                className: router.asPath.startsWith("/info/set-update") ? "s11" : "",
                                id: "app-bg"
                              }), React.createElement("div", {
                                className: "flex w-full pb-8 lg:max-w-[calc(100vw-30px)]"
                              }, React.createElement("div", {
                                    className: "flex flex-col items-center w-full"
                                  }, React.createElement(AppHeader2.make, {}), React.createElement("div", {
                                        className: "flex w-full justify-evenly"
                                      }, React.createElement(SidebarAd.make, {
                                            rail: "left",
                                            key: "l-" + router.asPath
                                          }), React.createElement("div", {
                                            className: "w-full md:w-[928px] md:max-w-[928px] xl:w-[1141px] xl:max-w-[1141px]"
                                          }, children), React.createElement(SidebarAd.make, {
                                            rail: "right",
                                            key: "r-" + router.asPath
                                          }))))), React.createElement("footer", {
                            className: "text-center text-xs py-6 px-4 max-w-[100vw] md:max-w-[790px] mx-auto mt-auto"
                          }, "tactics.tools isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.", React.createElement("div", {
                                className: "mt-4 flex items-center justify-center gap-6"
                              }, React.createElement("a", {
                                    href: "/privacy"
                                  }, "Privacy Policy"), React.createElement("a", {
                                    href: "/terms"
                                  }, "Terms of Service"), React.createElement(Consents.make, {})), typeof activeRoute === "number" && activeRoute < 2 ? React.createElement("div", {
                                  className: "mt-2"
                                }, React.cloneElement(React.createElement("span", undefined), {
                                      "data-ccpa-link": "1"
                                    })) : null)), React.createElement(CmdPalette.make, {}))
            })
      });
  return Curry._1(AppBoundary.make, {
              children: el
            });
}

var make = AppLayout;

export {
  make ,
  
}
/* Mui Not a pure module */
