import { useState, useCallback, useEffect } from "react";
import { make as LangSelect } from "../components/LangSelect.bs";
import Select from "../components/Select";
import { common } from "common/src/bindings/Translation2";
import { Switch, FormControlLabel } from "@mui/material";
import { atom_show_unit_names } from "common/src/SettingsCfg";
import { useAtom } from "jotai";

const Settings = () => {
  let id = useCallback((v: string) => v, []);
  const [theme, setThemeInner] = useState("dark");
  const [show_unit_names, set_show_unit_names] = useAtom(atom_show_unit_names);

  useEffect(() => {
    setThemeInner(localStorage.getItem("theme") || "dark");
  }, []);

  const setShowNames_ = useCallback((e: any) => {
    set_show_unit_names(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem("show_unit_names", e.target.checked);
    } else {
      localStorage.removeItem("show_unit_names");
    }
  }, []);

  const setTheme = useCallback(
    (theme: string) => {
      setThemeInner(theme);
      window.document.body.className = window.document.body.className.replace(
        /dark|light|midnight/gm,
        "",
      );
      window.document.body.className += " " + theme;
      localStorage.setItem("theme", theme);
    },
    [setThemeInner],
  );

  return (
    <>
      <h1 className="text-2xl font-montserrat font-medium">
        {common("Settings")}
      </h1>
      <div className="mt-8">
        <div>{common("Language")}</div>
        <LangSelect />
      </div>
      <div className="mt-6">
        <div>{common("Theme")}</div>
        <Select
          label="Theme"
          options={[
            ["dark", common("Dark")],
            ["midnight", common("Midnight")],
            // ["light", "Light"],
          ]}
          toKey={id}
          fromKey={id}
          value={theme}
          setValue={setTheme}
        />
      </div>
      <div className="mt-4">
        <FormControlLabel
          label="Show Unit Names On Images"
          control={
            <Switch checked={show_unit_names} onChange={setShowNames_} />
          }
        />
      </div>
    </>
  );
};

export default Settings;
