import { useEffect } from "react";
import { atom, useAtom } from "jotai";

export const atom_show_unit_names = atom(false);

export const init_cfg = () => {
  let [_show_unit_names, set_show_unit_names] = useAtom(atom_show_unit_names);
  useEffect(() => {
    set_show_unit_names(Boolean(localStorage.getItem("show_unit_names")));
  }, []);
};
