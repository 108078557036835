let data13;
const data14 = require("../static-s13/Data.json");
if (typeof window === "undefined") {
  // data13 = require("./Datatmp.json");
  // data13.units = data14.units;
  // data13.traits = data14.traits;
  // data13.items = data14.items;
  data13 = require("../static-s13/Data.json");
} else {
  // data13 = require("./Datatmp.json");
  // data13.units = data14.units;
  // data13.traits = data14.traits;
  // data13.items = data14.items;
  data13 = require("../static-s13/Data.json");
  window.data13 = data13;
  // data13 = window.data13;
}

export { data13 };
