// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LlIcon from "../components/LlIcon.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function RankCompanion(Props) {
  var tier = Props.tier;
  var companionId = Props.companionId;
  var tier$1 = Belt_Option.map(tier, (function (tier) {
          return Belt_Array.getExn(tier.split(" "), 0);
        }));
  return React.createElement("div", {
              className: "relative flex-shrink-0 h-8"
            }, React.createElement("img", {
                  className: "pointer-events-none absolute z-[1] min-w-[50px] left-[-12px] top-[-16px]",
                  height: "57",
                  src: "https://ap.tft.tools/img/banner/" + (
                    tier$1 !== undefined ? tier$1.toLowerCase() : "iron"
                  ) + "_base2.png",
                  width: "50"
                }), React.createElement(LlIcon.make, {
                  companionId: companionId,
                  size: 26,
                  className: "mt-[-1px]"
                }));
}

var make = RankCompanion;

export {
  make ,
  
}
/* react Not a pure module */
