// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ImgCoreTs from "./ImgCore.ts";

var getSrcSet = ImgCoreTs.getSrcSet;

var getSrcSet2 = ImgCoreTs.getSrcSet2;

export {
  getSrcSet ,
  getSrcSet2 ,
  
}
/* getSrcSet Not a pure module */
