import { Drawer } from "@mui/material";
import Link from "next/link";
import { useState, useCallback } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type NavLinkProps = {
  href?: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
  onClose: () => void;
};

const NavLink = ({ href, label, active, onClick, onClose }: NavLinkProps) => {
  if (href != null) {
    return (
      <Link
        prefetch={false}
        className={"drawer-nav-link " + (active ? "active" : "")}
        onClick={onClose}
        href={href}
      >
        {label}
      </Link>
    );
  } else {
    return (
      <div
        className={
          "drawer-nav-link flex justify-between " + (active ? "active" : "")
        }
        onClick={onClick}
      >
        {label}
        <ArrowForwardIosIcon fontSize="small" />
      </div>
    );
  }
};

type SideNavProps = {
  open: boolean;
  setOpen: (v: boolean) => void;
  activeGroup: string;
  rootGroups: any;
};

export default function SideNav({
  open,
  setOpen,
  rootGroups,
  activeGroup,
}: SideNavProps) {
  const [subheader, setSubheader] = useState<string | undefined>(undefined);
  const subheaderItems =
    rootGroups.find((group: any) => group.key === subheader)?.items || [];

  const onClose = useCallback(() => {
    setOpen(false);
    setSubheader(undefined);
  }, []);

  return (
    <Drawer
      classes={{
        paper: "bg-bg0 py-4 font-montserrat font-medium w-[180px] relative",
      }}
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div
        className={
          "side-nav-secondary " +
          (subheader == null
            ? "side-nav-secondary-hidden"
            : "side-nav-secondary-active")
        }
      >
        <div
          className="drawer-nav-link"
          onClick={() => setSubheader(undefined)}
        >
          <ArrowBackIosIcon fontSize="small" />
          {subheader}
        </div>
        {(subheader ? subheaderItems : []).map(([href, label]: any) => (
          <NavLink href={href} label={label} onClose={onClose} />
        ))}
      </div>
      <div
        className={
          "side-nav-primary " +
          (subheader == null
            ? "side-nav-primary-active"
            : "side-nav-primary-hidden")
        }
      >
        {rootGroups.map((group: any) => (
          <NavLink
            key={group.key}
            href={group.items.length === 0 ? group.href : undefined}
            onClick={
              group.items.length === 0
                ? undefined
                : () => setSubheader(group.key)
            }
            label={group.label}
            active={group.key === activeGroup}
            onClose={onClose}
          />
        ))}
      </div>
    </Drawer>
  );
}
