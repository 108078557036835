// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Route from "../Route.bs.js";
import * as Jotai from "jotai";
import * as React from "react";
import * as Config from "common/src/Config.bs.js";
import * as Region from "common/src/Region.bs.js";
import * as Router from "common/src/Router.bs.js";
import * as TftSet from "common/src/data/TftSet.bs.js";
import * as Amplitude from "common/src/Amplitude.bs.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as ApertureCtx from "../ctx/ApertureCtx.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as LocalStorage from "common/src/LocalStorage.bs.js";
import * as SnackbarService from "common/src/SnackbarService.bs.js";

function AppShell(Props) {
  var children = Props.children;
  var router = Router.use(undefined);
  React.useEffect((function () {
          var uid = LocalStorage.get("uid");
          var uid$1;
          if (uid == null) {
            var uid$2 = Uuid.v4();
            LocalStorage.set("uid", uid$2);
            uid$1 = uid$2;
          } else {
            uid$1 = uid;
          }
          var route = Route.fromAsPath(router.asPath);
          Amplitude.setUserId(uid$1);
          var tmp;
          if (typeof route === "number") {
            switch (route) {
              case /* Home */0 :
                  tmp = "Home";
                  break;
              case /* PrivacyPolicy */1 :
                  tmp = "Privacy Policy";
                  break;
              case /* TermsAndConditions */2 :
                  tmp = "Terms and Conditions";
                  break;
              case /* Patreon */3 :
                  tmp = "Patreon";
                  break;
              case /* TierList */4 :
                  tmp = "Tier List";
                  break;
              
            }
          } else {
            switch (route.TAG | 0) {
              case /* Stats */0 :
                  var tmp$1 = route._0;
                  if (typeof tmp$1 === "number") {
                    tmp = tmp$1 === /* Explorer */0 ? "Explorer" : "Advanced Explorer";
                  } else {
                    switch (tmp$1.TAG | 0) {
                      case /* Units */0 :
                          tmp = "Units";
                          break;
                      case /* Traits */1 :
                          tmp = "Traits";
                          break;
                      case /* Items */2 :
                          tmp = "Items";
                          break;
                      case /* Augments */3 :
                          tmp = "Augments";
                          break;
                      case /* Unit */4 :
                          tmp = "Unit";
                          break;
                      case /* Trait */5 :
                          tmp = "Trait";
                          break;
                      case /* Item */6 :
                          tmp = "Item";
                          break;
                      case /* Augment */7 :
                          tmp = "Augment";
                          break;
                      case /* Exalted */8 :
                          tmp = "Exalted";
                          break;
                      case /* ExaltedDetails */9 :
                          tmp = "Exalted Details";
                          break;
                      case /* TeamCompositions2 */10 :
                          tmp = "Team Comps";
                          break;
                      case /* HrComps */11 :
                          tmp = "Hr Comps";
                          break;
                      case /* DuoComps */12 :
                          tmp = "Duo Comps";
                          break;
                      case /* Trends */13 :
                          tmp = "Trends";
                          break;
                      
                    }
                  }
                  break;
              case /* TempComps */1 :
                  tmp = "Temp Comps";
                  break;
              case /* Player */2 :
                  tmp = "Player";
                  break;
              case /* TeamPlanner */3 :
                  tmp = "Team Builder";
                  break;
              case /* SetWrapped */4 :
                  tmp = "Set Wrapped " + String(route._0);
                  break;
              case /* Tools */5 :
                  var match = route._0;
                  tmp = typeof match === "number" ? (
                      match !== 0 ? "Perfect Synergies" : "Loaded Dice"
                    ) : "Unit Builder";
                  break;
              case /* Info */6 :
                  var tmp$2 = route._0;
                  if (typeof tmp$2 === "number") {
                    switch (tmp$2) {
                      case /* Main */0 :
                          tmp = "Info Main";
                          break;
                      case /* Hyperroll */1 :
                          tmp = "Info Hyperroll";
                          break;
                      case /* GoldExp */2 :
                          tmp = "Info Gold Exp";
                          break;
                      case /* Glossary */3 :
                          tmp = "Info Glossary";
                          break;
                      case /* CheatSheet */4 :
                          tmp = "Info Cheat Sheet";
                          break;
                      case /* Pbe */5 :
                          tmp = "Info Pbe";
                          break;
                      case /* TablesUnderground */6 :
                          tmp = "Tables Underground Heist";
                          break;
                      case /* TablesAdmin */7 :
                          tmp = "Tables Admin";
                          break;
                      case /* SetUpdate */8 :
                          tmp = "Info Set";
                          break;
                      
                    }
                  } else {
                    switch (tmp$2.TAG | 0) {
                      case /* Unit */0 :
                          tmp = "Info Single Unit";
                          break;
                      case /* Units */1 :
                          tmp = "Info Units";
                          break;
                      case /* Trait */2 :
                          tmp = "Info Trait";
                          break;
                      case /* Traits */3 :
                          tmp = "Info Traits";
                          break;
                      case /* Items */4 :
                          tmp = "Info Items";
                          break;
                      case /* Item */5 :
                          tmp = "Info Item";
                          break;
                      case /* Augments */6 :
                          tmp = "Info Augments";
                          break;
                      case /* PatchNotes */7 :
                          tmp = "Info Patch Notes";
                          break;
                      
                    }
                  }
                  break;
              case /* Leaderboards */7 :
                  var tmp$3 = route._0;
                  if (typeof tmp$3 === "number") {
                    tmp = "Bookmarks Leaderboards";
                  } else {
                    switch (tmp$3.TAG | 0) {
                      case /* Ranked */0 :
                          tmp = "Ranked Leaderboards";
                          break;
                      case /* Hyperroll */1 :
                          tmp = "Hyperroll Leaderboards";
                          break;
                      case /* Duo */2 :
                          tmp = "Duo Leaderboards";
                          break;
                      case /* Unit */3 :
                          tmp = "Unit Leaderboards";
                          break;
                      case /* Custom */4 :
                          tmp = "Custom Leaderboards";
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          var tmp$4;
          if (typeof route === "number") {
            switch (route) {
              case /* Home */0 :
                  tmp$4 = "Home";
                  break;
              case /* PrivacyPolicy */1 :
              case /* TermsAndConditions */2 :
              case /* Patreon */3 :
              case /* TierList */4 :
                  tmp$4 = "";
                  break;
              
            }
          } else {
            switch (route.TAG | 0) {
              case /* Stats */0 :
                  var match$1 = route._0;
                  if (typeof match$1 === "number") {
                    tmp$4 = "";
                  } else {
                    switch (match$1.TAG | 0) {
                      case /* Unit */4 :
                          tmp$4 = match$1.unit;
                          break;
                      case /* Trait */5 :
                          tmp$4 = match$1.trait;
                          break;
                      case /* Item */6 :
                      case /* Augment */7 :
                          tmp$4 = match$1.id;
                          break;
                      case /* ExaltedDetails */9 :
                          tmp$4 = String(match$1.gameType) + " " + String(match$1.tier);
                          break;
                      case /* Units */0 :
                      case /* Traits */1 :
                      case /* Items */2 :
                      case /* Augments */3 :
                      case /* Exalted */8 :
                      case /* TeamCompositions2 */10 :
                      case /* HrComps */11 :
                      case /* DuoComps */12 :
                      case /* Trends */13 :
                          tmp$4 = "";
                          break;
                      
                    }
                  }
                  break;
              case /* Player */2 :
                  var p = route._0;
                  tmp$4 = p !== undefined ? Region.toApiName(p.region) + " " + p.playerName : "";
                  break;
              case /* TeamPlanner */3 :
                  tmp$4 = Belt_Option.getWithDefault(route._0, "");
                  break;
              case /* TempComps */1 :
              case /* SetWrapped */4 :
                  tmp$4 = "";
                  break;
              case /* Tools */5 :
                  tmp$4 = "";
                  break;
              case /* Info */6 :
                  var set = route._0;
                  if (typeof set === "number") {
                    switch (set) {
                      case /* Main */0 :
                      case /* Hyperroll */1 :
                      case /* GoldExp */2 :
                      case /* Glossary */3 :
                      case /* CheatSheet */4 :
                      case /* Pbe */5 :
                      case /* TablesUnderground */6 :
                      case /* TablesAdmin */7 :
                      case /* SetUpdate */8 :
                          tmp$4 = "";
                          break;
                      
                    }
                  } else {
                    switch (set.TAG | 0) {
                      case /* Unit */0 :
                          tmp$4 = set._1 + Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return " " + String(v);
                                }));
                          break;
                      case /* Units */1 :
                          tmp$4 = Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return String(v);
                                }));
                          break;
                      case /* Trait */2 :
                          tmp$4 = set._1 + Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return " " + String(v);
                                }));
                          break;
                      case /* Traits */3 :
                          tmp$4 = Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return String(v);
                                }));
                          break;
                      case /* Items */4 :
                          tmp$4 = Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return String(v);
                                }));
                          break;
                      case /* Item */5 :
                          tmp$4 = set._1 + Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return " " + String(v);
                                }));
                          break;
                      case /* Augments */6 :
                          tmp$4 = Belt_Option.mapWithDefault(set._0, "", (function (v) {
                                  return String(v);
                                }));
                          break;
                      case /* PatchNotes */7 :
                          tmp$4 = Belt_Option.getWithDefault(set._0, "");
                          break;
                      
                    }
                  }
                  break;
              case /* Leaderboards */7 :
                  var region = route._0;
                  if (typeof region === "number") {
                    tmp$4 = "";
                  } else {
                    switch (region.TAG | 0) {
                      case /* Ranked */0 :
                      case /* Hyperroll */1 :
                      case /* Duo */2 :
                          tmp$4 = Belt_Option.mapWithDefault(region._0, "", Region.toApiName);
                          break;
                      case /* Unit */3 :
                          tmp$4 = Belt_Option.getWithDefault(region._0, "");
                          break;
                      case /* Custom */4 :
                          tmp$4 = region._0;
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          Amplitude.log2("Page visit", {
                locale: router.locale,
                page: tmp,
                subpage: tmp$4
              });
          var screenSize = ScreenAtom.getSize(ScreenAtom.getWidth(undefined));
          var tmp$5;
          switch (screenSize) {
            case /* XXl */0 :
                tmp$5 = "XXl";
                break;
            case /* Xl */1 :
                tmp$5 = "Xl";
                break;
            case /* Lg */2 :
                tmp$5 = "Lg";
                break;
            case /* Md */3 :
                tmp$5 = "Md";
                break;
            case /* Sm */4 :
                tmp$5 = "Sm";
                break;
            case /* Xs */5 :
                tmp$5 = "Xs";
                break;
            
          }
          Amplitude.log2("Screen", {
                width: ScreenAtom.getWidth(undefined),
                height: ScreenAtom.getHeight(undefined),
                size: tmp$5
              });
          
        }), []);
  return React.createElement("div", undefined, React.createElement(TftSet.Provider.make, TftSet.Provider.makeProps(TftSet.fromNum(Config.statsSet), React.createElement(Jotai.Provider, {
                          children: React.createElement(ApertureCtx.Provider.make, {
                                children: children
                              })
                        }), undefined)), React.createElement(SnackbarService.make, {}), React.createElement("svg", {
                  height: "0",
                  width: "0"
                }, React.createElement("linearGradient", {
                      id: "headliner-gradient",
                      gradientTransform: "rotate(90)"
                    }, React.createElement("stop", {
                          offset: "0%",
                          stopColor: "#23e7e3"
                        }), React.createElement("stop", {
                          offset: "50%",
                          stopColor: "#8bf629"
                        }), React.createElement("stop", {
                          offset: "100%",
                          stopColor: "#ff6d97"
                        }))));
}

var make = AppShell;

export {
  make ,
  
}
/* uuid Not a pure module */
