// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Global from "./Global.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TBindJs from "./T.bind.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function calcSafePlace(stdevAmountOpt, place, count) {
  var stdevAmount = stdevAmountOpt !== undefined ? stdevAmountOpt : 2.75;
  var stdev = 5.25 / Math.sqrt(count);
  return place + stdev * stdevAmount;
}

function calcSafeWinrate(invertOpt, winrate, count) {
  var invert = invertOpt !== undefined ? invertOpt : false;
  var stdev = 1 / Math.sqrt(count);
  if (invert) {
    return winrate + stdev * 2.75;
  } else {
    return winrate - stdev * 2.75;
  }
}

function calcSafeSynergy(stdevAmountOpt, invertOpt, winrate, count) {
  var stdevAmount = stdevAmountOpt !== undefined ? stdevAmountOpt : 1.5;
  var invert = invertOpt !== undefined ? invertOpt : false;
  var stdev = 5.25 / Math.sqrt(count);
  if (invert) {
    return winrate + stdev * stdevAmount;
  } else {
    return winrate - stdev * stdevAmount;
  }
}

function humanReadableCount(count) {
  if (count >= 10000000) {
    return String(count / 1000000 | 0) + "M";
  } else if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + "M";
  } else if (count >= 10000) {
    return String(count / 1000 | 0) + "k";
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1) + "k";
  } else {
    return String(count);
  }
}

function tap(v) {
  console.log(v);
  return v;
}

function tap2(v, s) {
  console.log(s, v);
  return v;
}

var _setPageTitle = ((title) => document.title = title);

function calcPlaceSynergy(placeTogether, place1, place2) {
  return placeTogether - (place1 + place2) / 2;
}

function getBestSynergies(synergies, count) {
  return Belt_SortArray.stableSortBy(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(synergies, (function (param) {
                            return param[1].synergy > 0;
                          })), (function (param, param$1) {
                        var u2 = param$1[1];
                        var u1 = param[1];
                        return -Caml.caml_float_compare(calcSafeSynergy(undefined, undefined, u1.synergy, u1.count), calcSafeSynergy(undefined, undefined, u2.synergy, u2.count)) | 0;
                      })), 0, count), (function (param, param$1) {
                return -Caml.caml_float_compare(param[1].synergy, param$1[1].synergy) | 0;
              }));
}

function getBestMatchups(matchups, count, avgWr) {
  return Belt_SortArray.stableSortBy(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(matchups, (function (m) {
                            return m.winrate > avgWr;
                          })), (function (m1, m2) {
                        return -Caml.caml_float_compare(calcSafeWinrate(undefined, m1.winrate, m1.count), calcSafeWinrate(undefined, m2.winrate, m2.count)) | 0;
                      })), 0, count), (function (m1, m2) {
                return -Caml.caml_float_compare(m1.winrate, m2.winrate) | 0;
              }));
}

function getWorstMatchups(matchups, count, avgWr) {
  return Belt_SortArray.stableSortBy(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(matchups, (function (m) {
                            return m.winrate < avgWr;
                          })), (function (m1, m2) {
                        return Caml.caml_float_compare(calcSafeWinrate(true, m1.winrate, m1.count), calcSafeWinrate(true, m2.winrate, m2.count));
                      })), 0, count), (function (m1, m2) {
                return Caml.caml_float_compare(m1.winrate, m2.winrate);
              }));
}

function fromStageStr(stageStr) {
  var split = stageStr.split("-");
  var stage = Belt_Option.getExn(Belt_Int.fromString(Belt_Array.getExn(split, 0)));
  var round = Belt_Option.getExn(Belt_Int.fromString(Belt_Array.getExn(split, 1)));
  if (stage === 1) {
    return round - 1 | 0;
  } else {
    return (4 + Math.imul(stage - 2 | 0, 7) | 0) + (round - 1 | 0) | 0;
  }
}

function renderStageNumber(stageNumber) {
  var stage = ((stageNumber - 4 | 0) / 7 | 0) + 2 | 0;
  var round = (stageNumber - 4 | 0) % 7;
  var match = round === 0 ? [
      stage - 1 | 0,
      7
    ] : [
      stage,
      round
    ];
  return String(match[0]) + "-" + String(match[1]);
}

function renderHyperrollStageNumber(stageNumber) {
  switch (stageNumber) {
    case 10 :
        return "6-1";
    case 11 :
        return "6-2";
    case 12 :
        return "6-3";
    case 13 :
        return "7-1";
    case 14 :
        return "7-2";
    case 15 :
        return "7-3";
    case 16 :
        return "8-1";
    case 17 :
        return "8-2";
    case 18 :
        return "8-3";
    case 19 :
        return "9-1";
    case 20 :
        return "9-2";
    case 21 :
        return "9-3";
    case 22 :
        return "9-4";
    case 23 :
        return "9-5";
    case 24 :
        return "9-6";
    case 25 :
        return "9-7";
    case 26 :
        return "9-8";
    case 27 :
        return "9-9";
    default:
      return "???";
  }
}

function toPvpStage(stageNumber) {
  if (stageNumber < 4) {
    return 0;
  }
  var stage = (stageNumber - 4 | 0) / 7 | 0;
  var round = (stageNumber - 4 | 0) % 7;
  var tmp;
  switch (round) {
    case 0 :
        tmp = 0;
        break;
    case 1 :
        tmp = 1;
        break;
    case 2 :
    case 3 :
        tmp = 2;
        break;
    case 4 :
        tmp = 3;
        break;
    case 5 :
    case 6 :
        tmp = 4;
        break;
    default:
      throw {
            RE_EXN_ID: "Assert_failure",
            _1: [
              "T.res",
              157,
              13
            ],
            Error: new Error()
          };
  }
  return Math.imul(stage, 5) + tmp | 0;
}

function fromPvpStage(pvpStage) {
  var stage = pvpStage / 5 | 0;
  var round = pvpStage % 5;
  var tmp;
  switch (round) {
    case 0 :
        tmp = 0;
        break;
    case 1 :
        tmp = 1;
        break;
    case 2 :
        tmp = 2;
        break;
    case 3 :
        tmp = 4;
        break;
    case 4 :
        tmp = 5;
        break;
    default:
      throw {
            RE_EXN_ID: "Assert_failure",
            _1: [
              "T.res",
              173,
              9
            ],
            Error: new Error()
          };
  }
  return (Math.imul(stage, 7) + tmp | 0) + 4 | 0;
}

function isNonLatinName(name) {
  var nonLatinChars = 0;
  for(var i = 0 ,i_finish = name.length; i <= i_finish; ++i){
    if (name.charCodeAt(i) > 255) {
      nonLatinChars = nonLatinChars + 1 | 0;
    }
    
  }
  return nonLatinChars > ((name.length / 2 | 0) - 1 | 0);
}

var isPupeteer = (() => typeof window !== 'undefined' && window.document.body.className === 'pupeteer');

function genHexPath(sideLength) {
  var p1 = Math.sin(Math.PI / 6 * 2);
  return "M" + (p1 * sideLength).toString() + " 0L" + (p1 * sideLength * 2).toString() + " " + (sideLength / 2).toString() + "L" + (p1 * sideLength * 2).toString() + " " + (sideLength * 1.5).toString() + "L" + (p1 * sideLength).toString() + " " + (sideLength * 2).toString() + "L0 " + (sideLength * 1.5).toString() + "L0 " + (sideLength * 0.5).toString() + "Z";
}

function id(x) {
  return x;
}

function subtractArr(arr1, arr2) {
  var arr = arr1.slice(0);
  Belt_Array.forEach(arr2, (function (el) {
          var idx = arr.indexOf(el);
          if (idx !== -1) {
            arr.splice(idx, 1);
            return ;
          }
          
        }));
  return arr;
}

function xxs_(rules) {
  return CssJs.media("(max-width: 374px)", rules);
}

function sm_(rules) {
  return CssJs.media("(min-width: 640px)", rules);
}

function md_(rules) {
  return CssJs.media("(min-width: 910px)", rules);
}

function lg_(rules) {
  return CssJs.media("(min-width: 1270px)", rules);
}

function xl_(rules) {
  return CssJs.media("(min-width: 1530px)", rules);
}

function xxl_(rules) {
  return CssJs.media("(min-width: 1770px)", rules);
}

function formatPct2(v) {
  return Global.formatPct2(1, v);
}

function toFixedSmartInner(f, _digits) {
  while(true) {
    var digits = _digits;
    var mult = Math.pow(10, digits);
    var rf = Math.round(f * mult) / mult;
    if (digits >= 4) {
      return rf.toFixed(4);
    }
    if (Math.abs(rf - f) < 1 / mult / 20) {
      return rf.toFixed(digits);
    }
    _digits = digits + 1 | 0;
    continue ;
  };
}

function toFixedSmart(f) {
  return toFixedSmartInner(f, 0);
}

function formatPctSmart(f) {
  return toFixedSmartInner(f * 100, 0) + "%";
}

function duoPlace(place) {
  return (place + 1 | 0) / 2 | 0;
}

function place(hp) {
  if (hp.queueId === 1160) {
    return duoPlace(hp.info.placement);
  } else {
    return hp.info.placement;
  }
}

function removeHpTempTraits(prim) {
  return TBindJs.removeHpTempTraits(prim);
}

function removeMatchTempTraits(prim) {
  return TBindJs.removeMatchTempTraits(prim);
}

function clipboardText(prim) {
  return TBindJs.clipboardText();
}

function fromHex(prim) {
  return TBindJs.fromHex(prim);
}

function toHex(prim) {
  return TBindJs.toHex(prim);
}

var setPageTitle = _setPageTitle;

var enDash = "–";

var emDash = "—";

export {
  calcSafePlace ,
  calcSafeWinrate ,
  calcSafeSynergy ,
  humanReadableCount ,
  tap ,
  tap2 ,
  _setPageTitle ,
  setPageTitle ,
  calcPlaceSynergy ,
  getBestSynergies ,
  getBestMatchups ,
  getWorstMatchups ,
  fromStageStr ,
  renderStageNumber ,
  renderHyperrollStageNumber ,
  toPvpStage ,
  fromPvpStage ,
  isNonLatinName ,
  isPupeteer ,
  genHexPath ,
  id ,
  subtractArr ,
  xxs_ ,
  sm_ ,
  md_ ,
  lg_ ,
  xl_ ,
  xxl_ ,
  formatPct2 ,
  toFixedSmartInner ,
  toFixedSmart ,
  formatPctSmart ,
  enDash ,
  emDash ,
  duoPlace ,
  place ,
  removeHpTempTraits ,
  removeMatchTempTraits ,
  clipboardText ,
  fromHex ,
  toHex ,
  
}
/* CssJs Not a pure module */
