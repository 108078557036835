// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "common/src/Router.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

var allLangs = [
  [
    "en",
    "English"
  ],
  [
    "ko",
    "한국어"
  ],
  [
    "ja",
    "日本語"
  ],
  [
    "pt",
    "Português"
  ],
  [
    "fr",
    "Français"
  ],
  [
    "de",
    "Deutsch"
  ],
  [
    "es",
    "Español"
  ],
  [
    "zh",
    "简体中文"
  ],
  [
    "tc",
    "繁體中文"
  ],
  [
    "vie",
    "Tiếng Việt"
  ],
  [
    "ar",
    "Español Latino"
  ]
];

var getPath = (() => typeof window === 'undefined' ? '' : window.location.pathname);

var setPath = ((path) => {
    if (typeof window !== 'undefined') {
      window.location.pathname = path
    }
  });

function LangSelect(Props) {
  var screenSize = ScreenAtom.use(undefined);
  var router = Router.use(undefined);
  return React.createElement("div", {
              className: "relative"
            }, React.createElement(Select, {
                  native: screenSize >= 4,
                  value: router.locale,
                  onChange: (function (e) {
                      var v = e.target.value;
                      var path = getPath();
                      var hasLocale = allLangs.find(function (lang) {
                            if (path.startsWith("/" + lang[0] + "/")) {
                              return true;
                            } else {
                              return path === "/" + lang[0];
                            }
                          });
                      var basePath = hasLocale !== undefined ? path.slice(hasLocale[0].length + 1 | 0) : path;
                      var newPath = v === "en" ? basePath : "/" + v + basePath;
                      return setPath(newPath);
                    }),
                  variant: "standard",
                  children: screenSize >= 4 ? Belt_Array.map(allLangs, (function (param) {
                            var key = param[0];
                            return React.createElement("option", {
                                        key: key,
                                        value: key
                                      }, param[1]);
                          })) : Belt_Array.map(allLangs, (function (param) {
                            var key = param[0];
                            return React.createElement(MenuItem, {
                                        value: key,
                                        children: param[1],
                                        key: key
                                      });
                          }))
                }));
}

var make = LangSelect;

export {
  allLangs ,
  getPath ,
  setPath ,
  make ,
  
}
/* react Not a pure module */
