// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "common/src/Hooks.bs.js";
import * as React from "react";
import * as Region from "common/src/Region.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RankCompanion from "../common/RankCompanion.bs.js";
import ButtonBase from "@mui/material/ButtonBase";

function encode(t) {
  var puuid = t.puuid;
  if (puuid !== undefined) {
    return [
            t.region,
            puuid
          ];
  }
  var tag = t.tag;
  if (tag !== undefined) {
    return [
            t.region,
            t.name + "#" + tag
          ];
  } else {
    return [
            t.region,
            t.name
          ];
  }
}

function use(param) {
  return Hooks.useLsState("player-bookmarks", (function (param) {
                return [];
              }));
}

function PlayerBookmark$Card(Props) {
  var bookmark = Props.bookmark;
  var match = Hooks.useLsState("player-bookmarks", (function (param) {
          return [];
        }));
  var hasDuplicateNames = Belt_Array.keep(match[0], (function (b) {
          return b.name === bookmark.name;
        })).length > 1;
  return React.createElement(ButtonBase, {
              classes: {
                root: "w-full"
              },
              children: React.createElement("div", {
                    className: "w-full flex items-center p-3 pb-2 pr-2 rounded bg-bg2"
                  }, React.createElement(RankCompanion.make, {
                        tier: bookmark.tier,
                        companionId: bookmark.littleLegend
                      }), React.createElement("div", {
                        className: "text-left text-lg leading-none truncate pb-1 pl-3"
                      }, hasDuplicateNames ? React.createElement("span", {
                              className: "text-xs"
                            }, Region.toName3(Region.fromApiName(bookmark.region)) + " ") : null, bookmark.name))
            });
}

var Card = {
  make: PlayerBookmark$Card
};

export {
  encode ,
  use ,
  Card ,
  
}
/* Hooks Not a pure module */
