// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Router from "next/router";

function use(param) {
  return Router.useRouter();
}

export {
  use ,
  
}
/* next/router Not a pure module */
