// Generated by ReScript, PLEASE EDIT WITH CARE


function toId(rg) {
  switch (rg) {
    case /* Master */0 :
        return "0";
    case /* Diamond */1 :
        return "1";
    case /* Gold */2 :
        return "2";
    case /* All */3 :
        return "3";
    case /* GM */4 :
        return "4";
    
  }
}

function fromId(rgid) {
  switch (rgid) {
    case "0" :
        return /* Master */0;
    case "1" :
        return /* Diamond */1;
    case "2" :
        return /* Gold */2;
    case "3" :
        return /* All */3;
    case "4" :
        return /* GM */4;
    default:
      return ;
  }
}

function toUrl(t) {
  switch (t) {
    case /* Master */0 :
        return "top";
    case /* Diamond */1 :
        return ;
    case /* Gold */2 :
        return "plat";
    case /* All */3 :
        return "all";
    case /* GM */4 :
        return "gm";
    
  }
}

function fromUrl(t) {
  switch (t) {
    case "all" :
        return /* All */3;
    case "gm" :
        return /* GM */4;
    case "high" :
        return /* Diamond */1;
    case "plat" :
        return /* Gold */2;
    case "top" :
        return /* Master */0;
    default:
      return ;
  }
}

export {
  toId ,
  fromId ,
  toUrl ,
  fromUrl ,
  
}
/* No side effect */
