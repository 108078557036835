// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Data from "../data/Data.bs.js";
import * as React from "react";
import * as TftSet from "../data/TftSet.bs.js";
import * as Tooltip from "../components/Tooltip.bs.js";
import * as Translation from "../bindings/Translation.bs.js";
import * as RitoDescription from "../components/RitoDescription.bs.js";
import * as RitoDescription2 from "../components/RitoDescription2.bs.js";

function AugmentTooltip$Contents(Props) {
  var id = Props.id;
  var set = TftSet.use(undefined);
  var t = Translation.useT(/* Augments */4);
  var desc = t(id + "_desc");
  var tmp;
  if (set === 10 || set >= 90) {
    tmp = React.createElement(RitoDescription2.make, {
          desc: desc
        });
  } else {
    var parts = RitoDescription.parse(Data.replaceAugmentVariables(undefined, desc, id, set));
    tmp = React.createElement(RitoDescription.make, {
          parts: parts
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "font-semibold pb-1 font-montserrat text-lg"
                }, t(id)), React.createElement("div", {
                  className: "flex items-start"
                }, React.createElement("div", {
                      className: "text-sm leading-snug"
                    }, tmp)));
}

var Contents = {
  make: AugmentTooltip$Contents
};

function AugmentTooltip(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.createElement(Tooltip.make, {
              title: React.createElement(AugmentTooltip$Contents, {
                    id: id
                  }),
              children: children
            });
}

var make = AugmentTooltip;

export {
  Contents ,
  make ,
  
}
/* Data Not a pure module */
