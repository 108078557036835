// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Jotai from "jotai";
import * as React from "react";

function getSize(width) {
  if (width >= 1753) {
    return /* XXl */0;
  } else if (width >= 1513) {
    return /* Xl */1;
  } else if (width >= 1253) {
    return /* Lg */2;
  } else if (width >= 910) {
    return /* Md */3;
  } else if (width >= 640) {
    return /* Sm */4;
  } else {
    return /* Xs */5;
  }
}

var getWidth = (() => typeof window === 'undefined' ? 1920 : document.documentElement.clientWidth);

var getHeight = (() => typeof window === 'undefined' ? 1080 : document.documentElement.clientHeight);

var size = Jotai.atom(/* Xl */1);

var sizeNoSSR = Jotai.atom(getSize(Curry._1(getWidth, undefined)));

function isMobile(t) {
  return t >= 4;
}

function useSetter(param) {
  var match = Jotai.useAtom(size);
  var setSize = match[1];
  var match$1 = Jotai.useAtom(sizeNoSSR);
  var setSizeNoSSR = match$1[1];
  React.useEffect((function () {
          var cb = function (param) {
            var width = Curry._1(getWidth, undefined);
            var size = getSize(width);
            Curry._1(setSize, size);
            return Curry._1(setSizeNoSSR, size);
          };
          window.addEventListener("resize", cb);
          cb(undefined);
          return (function (param) {
                    window.removeEventListener("resize", cb);
                    
                  });
        }), []);
  
}

function use(param) {
  return Jotai.useAtom(size)[0];
}

function useNoSSR(param) {
  return Jotai.useAtom(sizeNoSSR)[0];
}

export {
  getSize ,
  getWidth ,
  getHeight ,
  size ,
  sizeNoSSR ,
  isMobile ,
  useSetter ,
  use ,
  useNoSSR ,
  
}
/* size Not a pure module */
