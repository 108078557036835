export function mem1<P1, V>(fn: (p1: P1) => V): (p1: P1) => V {
  const memMap = new Map();
  return (p1: P1) => {
    const key = String(p1);
    if (memMap.has(key)) {
      return memMap.get(key);
    } else {
      const v = fn(p1);
      memMap.set(key, v);
      return v;
    }
  };
}

export function mem2<P1, P2, V>(
  fn: (p1: P1, p2: P2) => V
): (p1: P1, p2: P2) => V {
  const memMap = new Map();
  return (p1: P1, p2: P2) => {
    const key = `${p1}___${p2}`;
    if (memMap.has(key)) {
      return memMap.get(key);
    } else {
      const v = fn(p1, p2);
      memMap.set(key, v);
      return v;
    }
  };
}

export function mem3<P1, P2, P3, V>(
  fn: (p1: P1, p2: P2, p3: P3) => V
): (p1: P1, p2: P2, p3: P3) => V {
  const memMap = new Map();
  return (p1: P1, p2: P2, p3: P3) => {
    const key = `${p1}___${p2}___${p3}`;
    if (memMap.has(key)) {
      return memMap.get(key);
    } else {
      const v = fn(p1, p2, p3);
      memMap.set(key, v);
      return v;
    }
  };
}
