// Generated by ReScript, PLEASE EDIT WITH CARE

import RevAdBindJs from "./RevAd.bind.js";

var make = RevAdBindJs;

export {
  make ,
  
}
/* make Not a pure module */
