// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Patch from "./Patch.bs.js";
import * as Config from "./Config.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as GameType from "./GameType.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RankGroup from "./RankGroup.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var default_patch = /* Patch */{
  _0: Config.lastPatch
};

var $$default = {
  patch: default_patch,
  rankGroup: /* Diamond */1,
  gameType: /* Normal */0,
  queue: 1100
};

function toUrl(aperture) {
  var qid = aperture.queue;
  var queueStr = qid !== 1100 ? (
      qid !== 1130 ? (
          qid !== 1160 ? String(qid) : "duo"
        ) : "hr"
    ) : undefined;
  var patchStr = Caml_obj.caml_equal(aperture.patch, /* Patch */{
        _0: Config.lastPatch
      }) ? undefined : Patch.renderFull(aperture.patch);
  var rankGroupStr = RankGroup.toUrl(aperture.rankGroup);
  var galaxyStr = GameType.toUrl(aperture.gameType);
  return Belt_Array.keepMap([
                queueStr,
                patchStr,
                rankGroupStr,
                galaxyStr
              ], (function (x) {
                  return x;
                })).join("/");
}

function fromP2(slug) {
  if (!slug) {
    return [
            /* Patch */{
              _0: Config.lastPatch
            },
            /* Diamond */1
          ];
  }
  var rest = slug.tl;
  var hd = slug.hd;
  var patch = Patch.fromUrl(hd);
  if (patch === undefined) {
    return [
            /* Patch */{
              _0: Config.lastPatch
            },
            Belt_Option.getWithDefault(RankGroup.fromUrl(hd), /* Diamond */1)
          ];
  }
  var rg = Belt_Option.getWithDefault(rest ? RankGroup.fromUrl(rest.hd) : undefined, /* Diamond */1);
  return [
          patch,
          rg
        ];
}

function fromUrl(slug) {
  var slug$1 = Belt_List.fromArray(slug);
  if (!slug$1) {
    return $$default;
  }
  var hd = slug$1.hd;
  var queue;
  switch (hd) {
    case "duo" :
        queue = 1160;
        break;
    case "hr" :
        queue = 1130;
        break;
    default:
      var v = Belt_Int.fromString(hd);
      queue = v !== undefined ? (
          v !== 6000 ? (
              v !== 6110 ? (
                  v > 1000 && v < 2000 ? v : undefined
                ) : 6110
            ) : 6000
        ) : undefined;
  }
  var match = queue !== undefined ? fromP2(slug$1.tl) : fromP2(slug$1);
  return {
          patch: match[0],
          rankGroup: match[1],
          gameType: /* Normal */0,
          queue: Belt_Option.getWithDefault(queue, 1100)
        };
}

function validate(aperture) {
  return {
          patch: aperture.patch,
          rankGroup: aperture.rankGroup,
          gameType: /* Normal */0,
          queue: aperture.queue
        };
}

export {
  $$default ,
  $$default as default,
  toUrl ,
  fromP2 ,
  fromUrl ,
  validate ,
  
}
/* Patch Not a pure module */
