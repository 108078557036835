// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lzy from "../util/Lzy.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Data11BindJs from "./Data11.bind.js";

var data = Data11BindJs.data11;

var units = data.units;

var traits = data.traits;

var items = data.items;

var augments = data.augments;

var itemsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(items), (function (param) {
              var v = param[1];
              arr[v.id] = [
                param[0],
                v
              ];
              
            }));
      return arr;
    });

function itemsArr() {
  return Lzy.get(itemsArr_);
}

function gridItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var y$1 = match[1];
  var x$1 = match[0];
  if (y$1 === 9) {
    if (x$1 === 9) {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45);
    } else {
      return Belt_Array.getExn(Lzy.get(itemsArr_), 45 + x$1 | 0);
    }
  } else {
    return Belt_Array.getExn(Lzy.get(itemsArr_), (((1 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + y$1 | 0);
  }
}

var augmentsArr_ = Lzy.make(function (param) {
      var arr = [];
      Belt_Array.forEachU(Js_dict.entries(augments), (function (param) {
              var v = param[1];
              if (Belt_Option.isNone(v.typ)) {
                arr[v.id] = [
                  param[0],
                  v
                ];
                return ;
              }
              
            }));
      return arr;
    });

function augmentsArr() {
  return Lzy.get(augmentsArr_);
}

function gridRadiantItem(x, y) {
  var match = x <= y ? [
      x,
      y
    ] : [
      y,
      x
    ];
  var x$1 = match[0];
  return Belt_Array.getExn(Lzy.get(itemsArr_), (((46 + Math.imul(x$1, 7) | 0) - (Math.imul(x$1 - 1 | 0, x$1 - 1 | 0) / 2 | 0) | 0) + ((x$1 - 1 | 0) / 2 | 0) | 0) + match[1] | 0);
}

function getItemTyp(id) {
  return "Craftable";
}

export {
  data ,
  units ,
  traits ,
  items ,
  augments ,
  itemsArr_ ,
  itemsArr ,
  gridItem ,
  augmentsArr_ ,
  augmentsArr ,
  gridRadiantItem ,
  getItemTyp ,
  
}
/* data Not a pure module */
