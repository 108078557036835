export const getSizeClass = (
  size: number,
  sizeSm = size,
  sizeMd = sizeSm,
  sizeLg = sizeMd
) => {
  const sizeClass: Array<string> = [];
  if (sizeLg !== sizeMd) {
    sizeClass.push(`lg:w-[${sizeLg}px]`);
  }
  if (sizeMd !== sizeSm) {
    sizeClass.push(`md:w-[${sizeMd}px]`);
  }
  if (sizeSm !== size) {
    sizeClass.push(`sm:w-[${sizeSm}px]`);
  }
  sizeClass.push(`w-[${size}px]`);

  return sizeClass.join(" ");
};

export const getSrcSet = (
  src: string,
  size: number,
  sizeSm = size,
  sizeMd = sizeSm,
  sizeLg = sizeMd
) => {
  const sizes: Array<string> = [];
  const sizeClass: Array<string> = [];

  const srcSizes = new Set();

  if (sizeLg !== sizeMd) {
    sizes.push(
      `(min-width: 1270px) ${sizeLg}px, (min-width: 1270px) ${
        sizeLg * 2
      }px 2x, (min-width: 1270px) ${sizeLg * 3}px 3x`
    );
    srcSizes.add(sizeLg);
    srcSizes.add(sizeLg * 2);
    srcSizes.add(sizeLg * 3);
    sizeClass.push(`lg:w-[${sizeLg}px]`);
  }
  if (sizeMd !== sizeSm) {
    sizes.push(
      `(min-width: 910px) ${sizeMd}px, (min-width: 910px) ${
        sizeMd * 2
      }px 2x, (min-width: 910px) ${sizeMd * 3}px 3x`
    );
    srcSizes.add(sizeMd);
    srcSizes.add(sizeMd * 2);
    srcSizes.add(sizeMd * 3);
    sizeClass.push(`md:w-[${sizeMd}px]`);
  }
  if (sizeSm !== size) {
    sizes.push(
      `(min-width: 640px) ${sizeSm}px, (min-width: 640px) ${
        sizeSm * 2
      }px 2x, (min-width: 640px) ${sizeSm * 3}px 3x`
    );
    srcSizes.add(sizeSm);
    srcSizes.add(sizeSm * 2);
    srcSizes.add(sizeSm * 3);
    sizeClass.push(`sm:w-[${sizeSm}px]`);
  }
  sizes.push(`${size}px, ${size * 2}px 2x, ${size * 3}px 3x`);
  srcSizes.add(size);
  srcSizes.add(size * 2);
  srcSizes.add(size * 3);
  sizeClass.push(`w-[${size}px]`);

  return [
    sizes.join(", "),
    Array.from(srcSizes)
      .map((size) => `${src}?width=${size} ${size}w`)
      .join(", "),
    sizeClass.join(" "),
  ];
};

export const getSrcSet2 = (
  src: string,
  size: number,
  sizeSm = size,
  sizeMd = sizeSm,
  sizeLg = sizeMd,
  borderSize = 0
) => {
  const sizes: Array<string> = [];
  const sizeClass: Array<string> = [];

  const srcSizes = new Set();

  if (sizeLg !== sizeMd) {
    sizes.push(
      `(min-width: 1270px) ${sizeLg - borderSize * 2}px, (min-width: 1270px) ${
        sizeLg * 2 - borderSize * 2
      }px 2x, (min-width: 1270px) ${sizeLg * 3 - borderSize * 2}px 3x`
    );
    srcSizes.add(sizeLg - borderSize * 2);
    srcSizes.add(sizeLg * 2 - borderSize * 2);
    srcSizes.add(sizeLg * 3 - borderSize * 2);
    sizeClass.push(`lg:w-[${sizeLg}px]`);
  }
  if (sizeMd !== sizeSm) {
    sizes.push(
      `(min-width: 910px) ${sizeMd - borderSize * 2}px, (min-width: 910px) ${
        sizeMd * 2 - borderSize * 2
      }px 2x, (min-width: 910px) ${sizeMd * 3 - borderSize * 2}px 3x`
    );
    srcSizes.add(sizeMd - borderSize * 2);
    srcSizes.add(sizeMd * 2 - borderSize * 2);
    srcSizes.add(sizeMd * 3 - borderSize * 2);
    sizeClass.push(`md:w-[${sizeMd}px]`);
  }
  if (sizeSm !== size) {
    sizes.push(
      `(min-width: 640px) ${sizeSm - borderSize * 2}px, (min-width: 640px) ${
        sizeSm * 2 - borderSize * 2
      }px 2x, (min-width: 640px) ${sizeSm * 3 - borderSize * 2}px 3x`
    );
    srcSizes.add(sizeSm - borderSize * 2);
    srcSizes.add(sizeSm * 2 - borderSize * 2);
    srcSizes.add(sizeSm * 3 - borderSize * 2);
    sizeClass.push(`sm:w-[${sizeSm}px]`);
  }
  sizes.push(
    `${size - borderSize * 2}px, ${size * 2 - borderSize * 2}px 2x, ${
      size * 3 - borderSize * 2
    }px 3x`
  );
  srcSizes.add(size - borderSize * 2);
  srcSizes.add(size * 2 - borderSize * 2);
  srcSizes.add(size * 3 - borderSize * 2);
  sizeClass.push(`w-[${size}px]`);

  return [
    sizes.join(", "),
    Array.from(srcSizes)
      .map((size) => `${src}?w=${size} ${size}w`)
      .join(", "),
    sizeClass.join(" "),
  ];
};
