// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Tooltip from "@mui/material/Tooltip";

function Tooltip$1(Props) {
  var classes = Props.classes;
  var disabledOpt = Props.disabled;
  var title = Props.title;
  var arrowOpt = Props.arrow;
  var enterDelayOpt = Props.enterDelay;
  var enterNextDelayOpt = Props.enterNextDelay;
  var enterTouchDelayOpt = Props.enterTouchDelay;
  var leaveTouchDelayOpt = Props.leaveTouchDelay;
  var onOpen = Props.onOpen;
  var onClose = Props.onClose;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var arrow = arrowOpt !== undefined ? arrowOpt : true;
  var enterDelay = enterDelayOpt !== undefined ? enterDelayOpt : 850;
  var enterNextDelay = enterNextDelayOpt !== undefined ? enterNextDelayOpt : 450;
  var enterTouchDelay = enterTouchDelayOpt !== undefined ? enterTouchDelayOpt : 600;
  var leaveTouchDelay = leaveTouchDelayOpt !== undefined ? leaveTouchDelayOpt : 1200;
  if (disabled) {
    return children;
  }
  var tmp = {
    arrow: arrow,
    enterDelay: enterDelay,
    enterNextDelay: enterNextDelay,
    enterTouchDelay: enterTouchDelay,
    leaveTouchDelay: leaveTouchDelay,
    title: title,
    children: children
  };
  if (onOpen !== undefined) {
    tmp.onOpen = Caml_option.valFromOption(onOpen);
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  if (classes !== undefined) {
    tmp.classes = Caml_option.valFromOption(classes);
  }
  return React.createElement(Tooltip, tmp);
}

var make = Tooltip$1;

var delta = "Delta shows how much adding the unit in the row affects the placement of the selection.";

var relDelta = "Rel. Delta shows how much adding the selection affects the placement of the unit in the row.";

export {
  make ,
  delta ,
  relDelta ,
  
}
/* react Not a pure module */
