// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Colors from "./Colors.bs.js";
import * as Styles from "@mui/material/styles";

var ThemeProvider = {};

var Paper = {};

var StylesProvider = {};

var StyledEngineProvider = {};

var Tabs = {};

var Tab = {};

var TextField = {};

var InputBase = {};

var Input = {};

var InputAdornment = {};

var Tooltip = {};

var Button = {};

var ButtonBase = {};

var Collapse = {};

var Menu = {};

var MenuItem = {};

var FormControl = {};

var FormLabel = {};

var FormControlLabel = {};

var Select = {};

var CircularProgress = {};

var IconButton = {};

var Popover = {};

var Popper = {};

var Checkbox = {};

var Switch = {};

var Slider = {};

var InputLabel = {};

var Modal = {};

var Snackbar = {};

var SwipeableDrawer = {};

var RadioGroup = {};

var Radio = {};

var Fab = {};

var Autocomplete = {};

var mainTheme = Styles.createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: Colors.primaryMui
        },
        secondary: {
          main: Colors.accentMui
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            outlinedPrimary: {
              color: "var(--primary-11)",
              border: "2px solid hsla(209, 100%, 66.3%, 0.65)",
              "&:hover": {
                border: "2px solid var(--primary-11a)"
              }
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--primary-4)"
            }
          }
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              paddingTop: "4px",
              paddingBottom: "4px"
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              maxWidth: "320px",
              backgroundColor: "var(--primary-4)",
              padding: "12px",
              fontWeight: "400",
              fontSize: "12px",
              boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
            },
            arrow: {
              color: "var(--primary-4)"
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              "@media(min-width: 600px)": {
                minHeight: "36px"
              }
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: "0.8125rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
              minHeight: "36px",
              padding: "4px 10px",
              minWidth: "80px",
              "@media(min-width: 600px)": {
                fontSize: "0.875rem",
                minWidth: "80px",
                minHeight: "36px",
                padding: "4px 12px"
              },
              "@media(min-width: 1920px)": {
                minWidth: "88px"
              }
            },
            textColorPrimary: {
              color: "var(--white2)"
            }
          }
        },
        MuiList: {
          styleOverrides: {
            padding: {
              paddingTop: "6px",
              paddingBottom: "6px"
            }
          }
        },
        MuiListItem: {
          styleOverrides: {
            gutters: {
              paddingLeft: "12px",
              paddingRight: "12px"
            }
          }
        },
        PrivateSwitchBase: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        }
      }
    });

var midnightTheme = Styles.createTheme({
      palette: {
        mode: "dark",
        primary: {
          main: Colors.primaryMui
        },
        secondary: {
          main: Colors.accentMui
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            outlinedPrimary: {
              color: "var(--primary-11)",
              border: "2px solid hsla(209, 100%, 66.3%, 0.65)",
              "&:hover": {
                border: "2px solid var(--primary-11a)"
              }
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--primary-4)"
            }
          }
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              paddingTop: "4px",
              paddingBottom: "4px"
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              maxWidth: "320px",
              backgroundColor: "var(--primary-3)",
              padding: "12px",
              fontWeight: "400",
              fontSize: "12px",
              boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
            },
            arrow: {
              color: "var(--primary-3)"
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              "@media(min-width: 600px)": {
                minHeight: "36px"
              }
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: "0.8125rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
              minHeight: "36px",
              padding: "4px 10px",
              minWidth: "80px",
              "@media(min-width: 600px)": {
                fontSize: "0.875rem",
                minWidth: "80px",
                minHeight: "36px",
                padding: "4px 12px"
              },
              "@media(min-width: 1920px)": {
                minWidth: "88px"
              }
            },
            textColorPrimary: {
              color: "var(--white2)"
            }
          }
        },
        MuiList: {
          styleOverrides: {
            padding: {
              paddingTop: "6px",
              paddingBottom: "6px"
            }
          }
        },
        MuiListItem: {
          styleOverrides: {
            gutters: {
              paddingLeft: "12px",
              paddingRight: "12px"
            }
          }
        },
        PrivateSwitchBase: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        }
      }
    });

var lightTheme = Styles.createTheme({
      palette: {
        primary: {
          main: Colors.primaryMuiLight
        },
        secondary: {
          main: Colors.accentMuiLight
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            outlinedPrimary: {
              color: "var(--primary-11)",
              border: "2px solid hsla(211, 100%, 43.2%, 0.65)",
              "&:hover": {
                border: "2px solid var(--primary-11a)"
              }
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "var(--primary-4)"
            }
          }
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              paddingTop: "4px",
              paddingBottom: "4px"
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              maxWidth: "320px",
              backgroundColor: "var(--primary-3)",
              padding: "12px",
              fontWeight: "400",
              fontSize: "12px",
              boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
            },
            arrow: {
              color: "var(--primary-4)"
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: "36px",
              "@media(min-width: 600px)": {
                minHeight: "36px"
              }
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: "0.8125rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
              minHeight: "36px",
              padding: "4px 10px",
              minWidth: "80px",
              "@media(min-width: 600px)": {
                fontSize: "0.875rem",
                minWidth: "80px",
                minHeight: "36px",
                padding: "4px 12px"
              },
              "@media(min-width: 1920px)": {
                minWidth: "88px"
              }
            },
            textColorPrimary: {
              color: "var(--white2)"
            }
          }
        },
        MuiList: {
          styleOverrides: {
            padding: {
              paddingTop: "6px",
              paddingBottom: "6px"
            }
          }
        },
        MuiListItem: {
          styleOverrides: {
            gutters: {
              paddingLeft: "12px",
              paddingRight: "12px"
            }
          }
        },
        PrivateSwitchBase: {
          styleOverrides: {
            root: {
              padding: "6px"
            }
          }
        }
      }
    });

export {
  ThemeProvider ,
  Paper ,
  StylesProvider ,
  StyledEngineProvider ,
  Tabs ,
  Tab ,
  TextField ,
  InputBase ,
  Input ,
  InputAdornment ,
  Tooltip ,
  Button ,
  ButtonBase ,
  Collapse ,
  Menu ,
  MenuItem ,
  FormControl ,
  FormLabel ,
  FormControlLabel ,
  Select ,
  CircularProgress ,
  IconButton ,
  Popover ,
  Popper ,
  Checkbox ,
  Switch ,
  Slider ,
  InputLabel ,
  Modal ,
  Snackbar ,
  SwipeableDrawer ,
  RadioGroup ,
  Radio ,
  Fab ,
  Autocomplete ,
  mainTheme ,
  midnightTheme ,
  lightTheme ,
  
}
/* mainTheme Not a pure module */
