// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MantineTs from "./Mantine.ts";

var Modal = {};

var Provider = {};

var theme = MantineTs.theme;

var midnightTheme = MantineTs.midnightTheme;

var lightTheme = MantineTs.lightTheme;

export {
  Modal ,
  Provider ,
  theme ,
  midnightTheme ,
  lightTheme ,
  
}
/* theme Not a pure module */
