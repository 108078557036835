// Generated by ReScript, PLEASE EDIT WITH CARE

import NitroAdBindJs from "./NitroAd.bind.js";

var make = NitroAdBindJs;

export {
  make ,
  
}
/* make Not a pure module */
