import React from "react";
import Link from "next/link";
// import * as Sentry from "@sentry/node";
import { isProd } from "./Env.bind";

class AppBoundaryClass extends React.Component {
  state = { hasError: false, error: null };

  componentDidCatch(error, errorInfo) {
    // Sentry.captureException(error, { extra: errorInfo });
  }

  static getDerivedStateFromError(error) {
    if (isProd) {
      return {
        hasError: true,
        error,
      };
    } else {
      throw error;
      return {};
    }
  }

  render() {
    if (this.state.hasError) {
      // console.log(this.state.error);
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100vw - 17px)",
            height: "calc(100vh)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: "500",
            }}
          >
            Error has occured. Try refreshing the page with
            <span
              style={{
                color: "#80CBC4",
                fontWeight: "medium",
              }}
            >
              {" Ctrl+Shift+R "}
            </span>
            to continue or go back to{" "}
            <Link href="/">
              <a
                style={{
                  color: "#80CBC4",
                  fontWeight: "medium",
                }}
              >
                Home
              </a>
            </Link>
            .
          </div>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "500",
              paddingTop: "14px",
            }}
          >
            If this keeps repeating please report this to
            <a
              href="https://discord.gg/K4Z6shucH8"
              target="_blank"
              style={{
                color: "#80CBC4",
                fontWeight: "medium",
              }}
            >
              {" Discord"}
            </a>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default function AppBondary({ children }) {
  return <AppBoundaryClass>{children}</AppBoundaryClass>;
}
