// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ConfigTs from "./Config.ts";

var lastPatch = ConfigTs.lastPatch;

var allPatches = ConfigTs.allPatches;

var statsSet = ConfigTs.statsSet;

var reviqThreshold = ConfigTs.REVIQ_THRESHOLD;

export {
  lastPatch ,
  allPatches ,
  statsSet ,
  reviqThreshold ,
  
}
/* lastPatch Not a pure module */
