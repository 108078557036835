// Generated by ReScript, PLEASE EDIT WITH CARE


function toId(t) {
  switch (t) {
    case /* Normal */0 :
        return "1";
    case /* Neekoverse */1 :
        return "2";
    case /* PurpleHaze */2 :
        return "3";
    case /* MediumLegends */3 :
        return "4";
    case /* SuperDense */4 :
        return "5";
    case /* TradeSector */5 :
        return "6";
    case /* StarCluster */6 :
        return "7";
    case /* TreasureTrove */7 :
        return "8";
    case /* GalacticArmory */8 :
        return "9";
    case /* LittleLittleLegends */9 :
        return "10";
    case /* BinaryStar */10 :
        return "11";
    case /* DwarfPlanet */11 :
        return "12";
    case /* PlunderPlanet */12 :
        return "13";
    case /* SalvageWorld */13 :
        return "14";
    case /* ManateesDelight */14 :
        return "15";
    case /* BigBang */15 :
        return "16";
    
  }
}

function fromId(t) {
  switch (t) {
    case "1" :
        return /* Normal */0;
    case "10" :
        return /* LittleLittleLegends */9;
    case "11" :
        return /* BinaryStar */10;
    case "12" :
        return /* DwarfPlanet */11;
    case "13" :
        return /* PlunderPlanet */12;
    case "14" :
        return /* SalvageWorld */13;
    case "15" :
        return /* ManateesDelight */14;
    case "16" :
        return /* BigBang */15;
    case "2" :
        return /* Neekoverse */1;
    case "3" :
        return /* PurpleHaze */2;
    case "4" :
        return /* MediumLegends */3;
    case "5" :
        return /* SuperDense */4;
    case "6" :
        return /* TradeSector */5;
    case "7" :
        return /* StarCluster */6;
    case "8" :
        return /* TreasureTrove */7;
    case "9" :
        return /* GalacticArmory */8;
    default:
      throw {
            RE_EXN_ID: "Assert_failure",
            _1: [
              "GameType.res",
              57,
              9
            ],
            Error: new Error()
          };
  }
}

function toName(t) {
  switch (t) {
    case /* Normal */0 :
        return "Normal Galaxy";
    case /* Neekoverse */1 :
        return "Neekoverse";
    case /* PurpleHaze */2 :
        return "Lilac Nebula";
    case /* MediumLegends */3 :
        return "Medium Legends";
    case /* SuperDense */4 :
        return "Super Dense";
    case /* TradeSector */5 :
        return "Trade Sector";
    case /* StarCluster */6 :
        return "Star Cluster";
    case /* TreasureTrove */7 :
        return "Treasure Trove";
    case /* GalacticArmory */8 :
        return "Galactic Armory";
    case /* LittleLittleLegends */9 :
        return "Littler Legends";
    case /* BinaryStar */10 :
        return "Binary Star";
    case /* DwarfPlanet */11 :
        return "Dwarf Planet";
    case /* PlunderPlanet */12 :
        return "Plunder Planet";
    case /* SalvageWorld */13 :
        return "Salvage World";
    case /* ManateesDelight */14 :
        return "Manatee's Delight";
    case /* BigBang */15 :
        return "Big Bang";
    
  }
}

function toUrl(t) {
  switch (t) {
    case /* Normal */0 :
        return ;
    case /* Neekoverse */1 :
        return "neekoverse";
    case /* PurpleHaze */2 :
        return "lilac-nebula";
    case /* MediumLegends */3 :
        return "medium-legends";
    case /* SuperDense */4 :
        return "super-dense";
    case /* TradeSector */5 :
        return "trade-sector";
    case /* StarCluster */6 :
        return "star-cluster";
    case /* TreasureTrove */7 :
        return "treasure-trove";
    case /* GalacticArmory */8 :
        return "galactic-armory";
    case /* LittleLittleLegends */9 :
        return "little-little-legends";
    case /* BinaryStar */10 :
        return "binary-star";
    case /* DwarfPlanet */11 :
        return "dwarf-planet";
    case /* PlunderPlanet */12 :
        return "plunder-planet";
    case /* SalvageWorld */13 :
        return "salvage-world";
    case /* ManateesDelight */14 :
        return "manatees-delight";
    case /* BigBang */15 :
        return "big-bang";
    
  }
}

function fromUrl(t) {
  switch (t) {
    case "big-bang" :
        return /* BigBang */15;
    case "binary-star" :
        return /* BinaryStar */10;
    case "dwarf-planet" :
        return /* DwarfPlanet */11;
    case "galactic-armory" :
        return /* GalacticArmory */8;
    case "lilac-nebula" :
        return /* PurpleHaze */2;
    case "little-little-legends" :
        return /* LittleLittleLegends */9;
    case "manatees-delight" :
        return /* ManateesDelight */14;
    case "medium-legends" :
        return /* MediumLegends */3;
    case "neekoverse" :
        return /* Neekoverse */1;
    case "normal-game" :
        return /* Normal */0;
    case "plunder-planet" :
        return /* PlunderPlanet */12;
    case "salvage-world" :
        return /* SalvageWorld */13;
    case "star-cluster" :
        return /* StarCluster */6;
    case "super-sense" :
        return /* SuperDense */4;
    case "trade-sector" :
        return /* TradeSector */5;
    case "treasure-trove" :
        return /* TreasureTrove */7;
    default:
      return ;
  }
}

function fromApiName(typ) {
  switch (typ) {
    case "TFT3_GameVariation_BigLittleLegends" :
        return /* MediumLegends */3;
    case "TFT3_GameVariation_Bonanza" :
        return /* TreasureTrove */7;
    case "TFT3_GameVariation_Dreadnova" :
        return /* PlunderPlanet */12;
    case "TFT3_GameVariation_Finale" :
        return /* BigBang */15;
    case "TFT3_GameVariation_FourCostFirstCarousel" :
        return /* PurpleHaze */2;
    case "TFT3_GameVariation_FreeNeekos" :
        return /* Neekoverse */1;
    case "TFT3_GameVariation_FreeRerolls" :
        return /* TradeSector */5;
    case "TFT3_GameVariation_FreeSpatula" :
        return /* ManateesDelight */14;
    case "TFT3_GameVariation_ItemsBreak" :
        return /* SalvageWorld */13;
    case "TFT3_GameVariation_LittlerLegends" :
        return /* LittleLittleLegends */9;
    case "TFT3_GameVariation_MidGameFoN" :
        return /* SuperDense */4;
    case "TFT3_GameVariation_None" :
        return /* Normal */0;
    case "TFT3_GameVariation_SmallerBoards" :
        return /* DwarfPlanet */11;
    case "TFT3_GameVariation_StartingItems" :
        return /* GalacticArmory */8;
    case "TFT3_GameVariation_TwoItemMax" :
        return /* BinaryStar */10;
    case "TFT3_GameVariation_TwoStarCarousels" :
        return /* StarCluster */6;
    default:
      throw {
            RE_EXN_ID: "Assert_failure",
            _1: [
              "GameType.res",
              139,
              9
            ],
            Error: new Error()
          };
  }
}

function getImgSrc(typ) {
  switch (typ) {
    case /* Normal */0 :
        return ;
    case /* Neekoverse */1 :
        return "/universe/neekoverse.png";
    case /* PurpleHaze */2 :
        return "/universe/lilacnebula.png";
    case /* MediumLegends */3 :
        return "/universe/big legends.png";
    case /* SuperDense */4 :
        return "/universe/superdense.png";
    case /* TradeSector */5 :
        return "/universe/tradesector.png";
    case /* StarCluster */6 :
        return "/universe/starcluster.png";
    case /* TreasureTrove */7 :
        return "/universe/bonanza.png";
    case /* GalacticArmory */8 :
        return "/universe/galacticarmory.png";
    case /* LittleLittleLegends */9 :
        return "/universe/littlelittlelegends.png";
    case /* BinaryStar */10 :
        return "/universe/binarystar.png";
    case /* DwarfPlanet */11 :
        return "/universe/dwarfplanet.png";
    case /* PlunderPlanet */12 :
        return "/universe/plunderplanet.png";
    case /* SalvageWorld */13 :
        return "/universe/salvageworld.png";
    case /* ManateesDelight */14 :
        return "/universe/manateesdelight.png";
    case /* BigBang */15 :
        return "/universe/bigbang.png";
    
  }
}

var all = [
  /* Normal */0,
  /* Neekoverse */1,
  /* PurpleHaze */2,
  /* MediumLegends */3,
  /* SuperDense */4,
  /* TradeSector */5,
  /* StarCluster */6,
  /* TreasureTrove */7,
  /* GalacticArmory */8,
  /* LittleLittleLegends */9,
  /* BinaryStar */10,
  /* DwarfPlanet */11,
  /* PlunderPlanet */12,
  /* SalvageWorld */13,
  /* ManateesDelight */14,
  /* BigBang */15
];

export {
  toId ,
  fromId ,
  toName ,
  toUrl ,
  fromUrl ,
  fromApiName ,
  getImgSrc ,
  all ,
  
}
/* No side effect */
