// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as HooksBindJs from "./Hooks.bind.js";

var setLocalStorage = ((k, v) => localStorage.setItem(k, v));

var getLocalStorage = (k => localStorage.getItem(k));

function useKeyDown0(cb) {
  React.useEffect((function () {
          document.addEventListener("keydown", cb);
          return (function (param) {
                    document.removeEventListener("keydown", cb);
                    
                  });
        }), []);
  
}

function useLsState(key, $$default) {
  var loadLs = function (param) {
    return Belt_Option.map(Caml_option.nullable_to_opt(getLocalStorage(key)), (function (v) {
                  return JSON.parse(v);
                }));
  };
  var match = React.useState(function () {
        return false;
      });
  var setLoaded = match[1];
  var match$1 = React.useState(function () {
        return Curry._1($$default, undefined);
      });
  var setV = match$1[1];
  var v = match$1[0];
  React.useEffect((function () {
          var l = loadLs(undefined);
          if (l !== undefined) {
            var v = Caml_option.valFromOption(l);
            Curry._1(setV, (function (param) {
                    return v;
                  }));
          }
          Curry._1(setLoaded, (function (param) {
                  return true;
                }));
          
        }), []);
  var firstRun = React.useRef(true);
  React.useEffect((function () {
          if (firstRun.current) {
            firstRun.current = false;
          } else {
            setLocalStorage(key, Belt_Option.getExn(JSON.stringify(v)));
          }
          
        }), [v]);
  var setV$1 = function (cb) {
    var currV = loadLs(undefined);
    if (currV === undefined) {
      return Curry._1(setV, cb);
    }
    var newV = Curry._1(cb, Caml_option.valFromOption(currV));
    return Curry._1(setV, (function (param) {
                  return newV;
                }));
  };
  return [
          v,
          setV$1,
          match[0]
        ];
}

var useSticky = HooksBindJs.useSticky;

export {
  setLocalStorage ,
  getLocalStorage ,
  useKeyDown0 ,
  useLsState ,
  useSticky ,
  
}
/* useSticky Not a pure module */
