// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LzyBindJs from "./Lzy.bind.js";

var make = LzyBindJs.make;

function get(prim) {
  return LzyBindJs.get(prim);
}

export {
  make ,
  get ,
  
}
/* make Not a pure module */
